import moment from 'moment';

export const shortDate = date => {
    return date ? moment(date).format('DD.MM.YYYY') : ''
}

export const shortDateTime = date => {
    return date ? moment(date).format('DD.MM.YYYY HH:mm') : ''
}
export const shortTimeDate = date => {
    return date ? moment(date).format('HH:mm DD.MM.YYYY') : ''
}

export const shortDateTimeUTF = date => {
    return date ? `${date.substring(0, 10)} ${date.substring(11, 16)}` : ''
}