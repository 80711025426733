import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchGetEntryPointList, fetchGetMortgageStatusList, fetchGetEstimateStatusList, fetchGetPlotStatusList, fetchGetProjectStatusList } from '../../../api/client-api';
import { fetchGetLandInspectionStatusList } from '../../../api/land-inspection-api';
import { fetchGetLandDrillingPileTypeList } from '../../../api/land-drilling-api';


export const fetchGetEntryPointListAsync = createAsyncThunk(
    "dictionary/fetchGetEntryPointListAsync",
    fetchGetEntryPointList
);

export const fetchGetEstimateStatusListAsync = createAsyncThunk(
    "dictionary/fetchGetEstimateStatusListAsync",
    fetchGetEstimateStatusList
);

export const fetchGetMortgageStatusListAsync = createAsyncThunk(
    "dictionary/fetchGetMortgageStatusListAsync",
    fetchGetMortgageStatusList
);

export const fetchGetPlotStatusListAsync = createAsyncThunk(
    "dictionary/fetchGetPlotStatusListAsync",
    fetchGetPlotStatusList
);

export const fetchGetProjectStatusListAsync = createAsyncThunk(
    "dictionary/fetchGetProjectStatusListAsync",
    fetchGetProjectStatusList
);

export const fetchGetLandInspectionStatusListAsync = createAsyncThunk(
    "dictionary/fetchGetLandInspectionStatusListAsync",
    fetchGetLandInspectionStatusList
);

export const fetchGetLandDrillingPileTypeistAsync = createAsyncThunk(
    "dictionary/ffetchGetLandDrillingPileTypeistAsync",
    fetchGetLandDrillingPileTypeList
);


const initialState = {
    entryPointList: [],
    estimateStatusList: [],
    mortgageStgatusList: [],
    projectStatusList: [],
    plotStatusList: [],
    landInspectionStatusList: [],
    drillingPileTypeList: [],
    status: 'idle'
}

export const dictionarySlice = createSlice({
    name: 'dictionary',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchGetEntryPointListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetEntryPointListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetEntryPointListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entryPointList = action.payload.data;
                }
            })
            .addCase(fetchGetEstimateStatusListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetEstimateStatusListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetEstimateStatusListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.estimateStatusList = action.payload.data;
                }
            })
            .addCase(fetchGetMortgageStatusListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetMortgageStatusListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetMortgageStatusListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.mortgageStgatusList = action.payload.data;
                }
            })
            .addCase(fetchGetPlotStatusListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetPlotStatusListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetPlotStatusListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.plotStatusList = action.payload.data;
                }
            })
            .addCase(fetchGetProjectStatusListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetProjectStatusListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetProjectStatusListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.projectStatusList = action.payload.data;
                }
            })
            .addCase(fetchGetLandInspectionStatusListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetLandInspectionStatusListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetLandInspectionStatusListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.landInspectionStatusList = action.payload.data;
                }
            })
            .addCase(fetchGetLandDrillingPileTypeistAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetLandDrillingPileTypeistAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetLandDrillingPileTypeistAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.drillingPileTypeList = action.payload.data;
                }
            })
    },
    reducers: {
    }
})


export const getEntryPointList = (state) => state.dictionary.entryPointList;
export const getEstimateStatusList = (state) => state.dictionary.estimateStatusList;
export const getMortgageStatusList = (state) => state.dictionary.mortgageStgatusList;
export const getPlotStatusList = (state) => state.dictionary.plotStatusList;
export const getProjectStatusList = (state) => state.dictionary.projectStatusList;
export const getInspectionStatusList = (state) => state.dictionary.landInspectionStatusList;
export const getDrillingPileTypeList = (state) => state.dictionary.drillingPileTypeList;
export default dictionarySlice.reducer