import { TDefault } from '@/model/default-type';
import { RootState } from '@/services';
import { createSlice } from '@reduxjs/toolkit';

const initialState: { entities: Array<TDefault>; open: boolean } = {
    entities: [],
    open: false,
}

export const statusHistoryListlice = createSlice({
    name: 'statusHistoryList',
    initialState,
    reducers: {
        setStatusHistoryList: ((state, action) => {
            state.entities = action.payload;
        }),
        setIsStatusHistoryListOpen: ((state, action) => {
            state.open = action.payload;
        }),
        clearStatusHistoryList: (state => {
            state.entities = [];
        }),
    }
})

export const { clearStatusHistoryList, setIsStatusHistoryListOpen, setStatusHistoryList } = statusHistoryListlice.actions;
export const getStatusHistoryList = (state: RootState) => state.statusHistoryList.entities;
export const getIsStatusHistoryListOpen = (state: RootState) => state.statusHistoryList.open;

export default statusHistoryListlice.reducer