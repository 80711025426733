import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/auth/authSlice';

import personListSlice from './slices/person-list/personListSlice';
import personSlice from './slices/person/personSlice';

import userListSlice from './slices/user-list/userListSlice';
import userSlice from './slices/user/userSlice';

import dictionarySlice from './slices/dictionary/dictionarySlice';

import agencyListSlice from './slices/agency-list/agencyListSlice';
import agencyTelegram from './slices/agency-telegram/agencyTelegramSlice';
import agencySlice from './slices/agency/agencySlice';
import agentSlice from './slices/agent/agentSlice';

import managerListSlice from './slices/manager-list/managerListSlice';

import clientDictionary from './slices/client-dictionary/clientDictionarySlice';
import clientListSlice from './slices/client-list/clientListSlice';
import clientSlice from './slices/client/clientSlice';

import landListSlice from './slices/land-list/landListSlice';
import landSlice from './slices/land/landSlice';

import landInspectionListSlice from './slices/land-inspection-list/landInspectionListSlice';
import landInspectionSlice from './slices/land-inspection/landInspectionSlice';

import landSearchListSlice from './slices/land-search-list/landSearchListSlice';
import landSearchSlice from './slices/land-search/landSearchSlice';

import landDrillingListSlice from './slices/land-drilling-list/landDrillingListSlice';
import landDrillingSlice from './slices/land-drilling/landDrillingSlice';

import specialistListSlice from './slices/specialist-list/specialistListSlice';
import specialistSlice from './slices/specialist/specialistSlice';

import activityStatusList from './slices/activity/activityStatusListSlice';
import activityTypeList from './slices/activity/activityTypeListSlice';
import activityType from './slices/activity/activityTypeSlice';

import appliactionSlice from './slices/application/applicationSlice';
import notificationSlice from './slices/notification-list/notificationSlice';
import roleListSlice from './slices/role-list/roleListSlice';

import houseList from './slices/house-list/houseListSlice';
import house from './slices/house/houseSlice';

import bankList from './slices/bank-list/bankListSlice';
import bank from './slices/bank/bankSlice';


import errorSlice from './slices/error/errorSlice';
import fileListSlice from './slices/file-list/fileListSlice';
import mapSlice from './slices/map/mapSlice';

import estimateList from './slices/estimate/estimateListSlice';
import estimate from './slices/estimate/estimateSlice';

import estimateRequestList from './slices/estimate-request/estimateRequestListSlice';
import estimateRequest from './slices/estimate-request/estimateRequestSlice';

import estimateRequestType from './slices/estimate-request-type/estimateRequestTypeSlice';
import estimateRequestTypetList from './slices/estimate-request-type/estimateRequestTypetListSlice';

import activityComment from './slices/activity-comment-slice';


import estimatorList from './slices/estimator-list-slice';

import activityDescription from './slices/activity-description-slice';
import statusHistoryList from './slices/status-history-list-slice';
import ticketList from './slices/ticket-list-slice';
import ticket from './slices/ticket-slice';

import { rtkQueryErrorLogger } from './errorHandler';



export const store = configureStore({
  reducer: {
    auth: authSlice,

    user: userSlice,
    userList: userListSlice,

    person: personSlice,
    personList: personListSlice,

    specialist: specialistSlice,
    specialistList: specialistListSlice,

    client: clientSlice,
    clientList: clientListSlice,
    clientDictionary,

    personListSlice: clientListSlice,
    managerList: managerListSlice,
    dictionary: dictionarySlice,

    agent: agentSlice,
    agency: agencySlice,
    agencyTelegram,
    agencyList: agencyListSlice,

    land: landSlice,
    landList: landListSlice,

    landInspection: landInspectionSlice,
    landInsectionList: landInspectionListSlice,

    landSearch: landSearchSlice,
    landSearchList: landSearchListSlice,

    landDrilling: landDrillingSlice,
    landDrillingList: landDrillingListSlice,

    activityType,
    activityTypeList,
    activityStatusList,

    notifiactionList: notificationSlice,
    application: appliactionSlice,

    estimateList,
    estimate,

    estimateRequestList,
    estimateRequest,

    estimateRequestType,
    estimateRequestTypetList,

    estimatorList,

    roleList: roleListSlice,
    fileList: fileListSlice,

    house,
    houseList,

    bank,
    bankList,

    activityDescription,

    map: mapSlice,

    statusHistoryList,
    ticketList,
    ticket,
    activityComment,

    error: errorSlice,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(rtkQueryErrorLogger),
  devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch & { error?: { message?: string } }