import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchAddAgent, fetchGetAgent, fetchUpdateAgent, } from '../../../api/agency-api';
import { TAgent } from '../../../model/agent-type';
import { clearErrorMessage, setErrorMessage } from '../error/errorSlice';


export const fetchAddAgentAsync = createAsyncThunk(
    "agent/fetchAddAgentAsync",
    fetchAddAgent
);

export const fetchUpdateAgentAsync = createAsyncThunk(
    "agent/fetchUpdateAgentAsync",
    fetchUpdateAgent
);

export const fetchGetAgentAsync = createAsyncThunk(
    "agent/fetchGetAgentAsync",
    fetchGetAgent
);

const agentInitialState: TAgent = {
    person: {
        fio: '',
        phone: '',
        email: '',
        personTypes: [],
        description: '',
    },
    isHidden: false,
}

export const error: { message?: string } = {
    message: ''
}

const initialState: { entity: TAgent; status: string } = {
    entity: agentInitialState,
    status: 'idle',
}

export const agentSlice = createSlice({
    name: 'agent',
    initialState,
    extraReducers: (builder) => {
        builder
            // get agent
            .addCase(fetchGetAgentAsync.pending, (state) => {
                clearErrorMessage();
                state.status = 'loading';
            })
            .addCase(fetchGetAgentAsync.rejected, (state, action) => {
                setErrorMessage({ ...action.error });
                state.status = 'idle';
            })
            .addCase(fetchGetAgentAsync.fulfilled, (state, action) => {

                if (action.payload.success) {
                    state.entity = {
                        ...action.payload.data.person,
                        ...action.payload.data,
                        agentId: action.payload.data.id,
                        personId: action.payload.data.person.id
                    }
                }
                state.status = 'idle';
            })
            // add agent
            .addCase(fetchAddAgentAsync.pending, (state) => {
                clearErrorMessage();
                state.status = 'loading';
            })
            .addCase(fetchAddAgentAsync.rejected, (state, action) => {
                setErrorMessage({ ...action.error })
                state.status = 'idle';
            })
            .addCase(fetchAddAgentAsync.fulfilled, (state) => {
                state.status = 'idle';
            })
            // update agent
            .addCase(fetchUpdateAgentAsync.pending, (state) => {
                clearErrorMessage();
                state.status = 'loading';
            })
            .addCase(fetchUpdateAgentAsync.rejected, (state, action) => {
                setErrorMessage({ ...action.error })
                state.status = 'idle';
            })
            .addCase(fetchUpdateAgentAsync.fulfilled, (state) => {
                state.status = 'idle';
            })
    },
    reducers: {
        clearAgent: (state => {
            state.entity = agentInitialState;
        }),
    }
})

export const { clearAgent } = agentSlice.actions;
export const getAgent = (state: RootState) => state.agent.entity;

export default agentSlice.reducer