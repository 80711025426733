import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAddAgency, fetchGetAgency, fetchUpdateAgency } from '../../../api/agency-api';


export const fetchAddAgencyAsync = createAsyncThunk(
    "agency/fetchAddAgencyAsync",
    fetchAddAgency
);

export const fetchUpdateAgencyAsync = createAsyncThunk(
    "agency/fetchUpdateAgencyAsync",
    fetchUpdateAgency
);

export const fetchGetAgencyAsync = createAsyncThunk(
    "agency/fetchGetAgencyAsync",
    fetchGetAgency
);

const initialState = {
    entity: {},
    agentList: [],
    status: 'idle'
}

export const agencySlice = createSlice({
    name: 'agency',
    initialState,
    extraReducers: (builder) => {
        builder
            // add agency
            .addCase(fetchAddAgencyAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAddAgencyAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchAddAgencyAsync.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            // update agency
            .addCase(fetchUpdateAgencyAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUpdateAgencyAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchUpdateAgencyAsync.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            // get agency list
            .addCase(fetchGetAgencyAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetAgencyAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetAgencyAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entity = action.payload.data;

                    if (action.payload.data?.agents)
                        state.agentList = action.payload.data.agents;
                }
            })
    },
    reducers: {
        clearAgency: (state => {
            state.entity = {};
            state.agentList = [];
        }),
    }
})

export const { clearAgency } = agencySlice.actions;
export const getAgency = (state) => state.agency.entity;
export const getAgentList = (state) => state.agency.agentList;

export default agencySlice.reducer