import { requestWithAuth } from "."
import { fetchAddEntity, fetchGetEntity, fetchUpdateEntity } from './common'


const ESTIMATE_API = '/estimate'
const URL = '/estimate'


export function fetchAddEstimate(data) {
    return fetchAddEntity(URL, data)
}

export function fetchAddFormalizedEstimate(data) {
    return fetchAddEntity(URL, { ...data, isFormalized: true })
}

export function fetchGetEstimate(id) {
    return fetchGetEntity(URL, id)
}

export function fetchUpdateEstimate(data) {
    return fetchUpdateEntity(URL, data)
}

export function fetchTakeEstimateToWork(id) {
    return requestWithAuth({
        url: ESTIMATE_API + '/to-work?id=' + id, options: { method: 'GET' }
    })
}

export function fetchGetEstimateList() {
    return requestWithAuth({
        url: ESTIMATE_API + '/get-list', options: { method: 'GET' }
    })
}

export function fetchGetEstimateTypeList() {
    return requestWithAuth({ url: URL + '/get-estimate-type-list', options: { method: 'GET' } })
}