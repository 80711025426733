import { requestWithAuth } from './index'

const LAND_INSPECTION_API = '/land-search'

export function fetchAddLandSearch(data) {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/add', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchGetLandSearch(id) {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/get?id=' + id, options: { method: 'GET' } })
}

export function fetchDeleteLandSearch(id) {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/delete?id=' + id, options: { method: 'GET' } })
}

export function fetchUpdateLandSearch(data) {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/update', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchGetLandSearchList() {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/get-list', options: { method: 'GET' } })
}


export function fetchDoneLandSearch(data) {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/done', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchToWorkLandSearch(data) {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/work', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchCancelLandSearch(data) {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/cancel', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}