import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchGetLandList, fetchLandtFindAndCount } from '../../../api/land-api';
import { TLand } from '../../../model/land-model/land-type';


export const fetchGetLandListAsync = createAsyncThunk(
    "land/fetchGetLandListAsync",
    fetchGetLandList
);

export const fetchLandFindAndCountAsync = createAsyncThunk(
    "clientList/fetchLandFindAndCountAsync",
    fetchLandtFindAndCount
);

const initialState: {
    count: number;
    entities: Array<TLand>;
    status: string;
} = {
    count: 0,
    entities: [],
    status: 'idle'
}

export const landListSlice = createSlice({
    name: 'land',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchGetLandListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetLandListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetLandListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data.map((land: TLand) => ({
                        ...land,
                        landDrilling: land['land_drillings']?.find(drill => drill.applicationStatusId === 3),
                        landDrillingList: land['land_drillings'],
                        landInspection: land['land_inspection']
                    }))
                }
            })

            // get clients count
            .addCase(fetchLandFindAndCountAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchLandFindAndCountAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchLandFindAndCountAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.count = action.payload.data.count;
                    state.entities = action.payload.data.rows.map((land: TLand) => ({
                        ...land,
                        landDrilling: land['land_drillings']?.find(drill => drill.applicationStatusId === 3),
                        landDrillingList: land['land_drillings'],
                        landInspection: land['land_inspection']
                    }))
                }
            })
    },
    reducers: {

    }
})


export const getLandList = (state: RootState) => state.landList.entities;
export const getLandCount = (state: RootState) => state.landList.count;
export const getLandListLoadStatus = (state: RootState) => state.landList.status;

export default landListSlice.reducer