import { requestWithAuth } from "."
import { fetchAddEntity, fetchFindAndCount, fetchGetEntity, fetchUpdateEntity } from './common'


const TICKET_API = '/ticket'
const URL = TICKET_API


export function fetchAddTicket(data) {
    return fetchAddEntity(URL, data)
}

export function fetchGetTicket(id) {
    return fetchGetEntity(URL, id)
}

export function fetchFindAndCountTicket(data) {
    return fetchFindAndCount(TICKET_API, data)
}

export function fetchUpdateTicket(data) {
    return fetchUpdateEntity(TICKET_API, data)
}

export function fetchTakeToWorkTicket(id) {
    return requestWithAuth({
        url: TICKET_API + '/to-work?id=' + id, options: { method: 'GET' }
    })
}

export function fetchTicketDone(id) {
    return requestWithAuth({
        url: TICKET_API + '/done?id=' + id, options: { method: 'GET' }
    })
}
