import axios from 'axios';
import { HOST_BOT } from '../../api';


export const sendToBot = (data) => {
    console.log('sendToBot', data);
    // Make a request for a user with a given ID
    axios.post(HOST_BOT, { ...data })
        .then(function (response) {
            // handle success
            console.log(response);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .finally(function () {
            // always executed
        });
}