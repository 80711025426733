import { requestWithAuth } from './index'

const LAND_INSPECTION_API = '/land-inspection'

export function fetchAddLandInspection(data) {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/add', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchGetLandInspection(id) {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/get?id=' + id, options: { method: 'GET' } })
}

export function fetchDeleteLandInspection(id) {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/delete?id=' + id, options: { method: 'GET' } })
}

export function fetchUpdateLandInspection(data) {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/update', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchLandInspectionToDone(data) {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/done', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchGetLandInspectionList() {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/get-list', options: { method: 'GET' } })
}

export function fetchGetLandInspectionListByClient(id) {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/get-list-by-client?id=' + id, options: { method: 'GET' } })
}

export function fetchGetLandInspectionListFromLandOffers() {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/get-list-from-land-offers', options: { method: 'GET' } })
}

export function fetchGetLandInspectionStatusList() {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/get-status-list', options: { method: 'GET' } })
}

export function fetchCheckLandInspectionByCadastre(cadastre) {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/check-cadastre?cadastre=' + cadastre, options: { method: 'GET' } })
}

export function fetchUpdateLandInspectionResult(data) {
    return requestWithAuth({ url: LAND_INSPECTION_API + '/change-result', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}