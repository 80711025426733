import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchAddLand, fetchGetLand, fetchGetLandWaterTypeList, fetchPullFromArchive, fetchPushToArchive, fetchUpdateLand, } from '../../../api/land-api';
import { TDefault } from '../../../model/default-type';
import { TLand } from '../../../model/land-model/land-type';


export const landApplicationAdapter = (land: TLand) => ({ ...land, landDrillingList: land['land_drillings'], landInspection: land['land_inspection'] })
export const landListApplicationAdapter = (landList: TLand[]) => landList.map((land) => landApplicationAdapter(land))


export const fetchAddLandAsync = createAsyncThunk(
    "land/fetchAddLandAsync",
    fetchAddLand
);

export const fetchUpdateLandAsync = createAsyncThunk(
    "land/fetchUpdateLandAsync",
    fetchUpdateLand
);

export const fetchGetLandAsync = createAsyncThunk(
    "land/fetchGetLandAsync",
    fetchGetLand
);

export const fetchGetLandWaterTypeListAsync = createAsyncThunk(
    "land/fetchGetLandWaterTypeListAsync",
    fetchGetLandWaterTypeList
);

export const fetchPushToArchiveAsync = createAsyncThunk(
    "land/fetchPushToArchiveAsync",
    fetchPushToArchive
);

export const fetchPullFromArchiveAsync = createAsyncThunk(
    "land/√",
    fetchPullFromArchive
);

const landInitialState: TLand = {
    coordinates: '',
    cadastre: '',
    isLandOffers: false,
    isLandFromAgency: false,
    description: '',
    buildingRulesResult: '',
    land_water_types: [],
    link: '',
}

const initialState: { entity: TLand; waterTypeList: TDefault[]; status: string } = {
    entity: landInitialState,
    waterTypeList: [],
    status: 'idle'
}

export const landSlice = createSlice({
    name: 'land',
    initialState,
    extraReducers: (builder) => {
        builder
            // get land
            .addCase(fetchGetLandAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetLandAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetLandAsync.fulfilled, (state, action) => {

                if (action.payload.success) {
                    state.entity = landApplicationAdapter(action.payload.data)
                }
                state.status = 'idle';
            })
            // get water type list
            .addCase(fetchGetLandWaterTypeListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetLandWaterTypeListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetLandWaterTypeListAsync.fulfilled, (state, action) => {

                if (action.payload.success) {
                    state.waterTypeList = action.payload.data
                }
                state.status = 'idle';
            })
            // add land
            .addCase(fetchAddLandAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAddLandAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchAddLandAsync.fulfilled, (state) => {
                state.status = 'idle';
            })
            // update land
            .addCase(fetchUpdateLandAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUpdateLandAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchUpdateLandAsync.fulfilled, (state) => {
                state.status = 'idle';
            })
            // land push to archvie
            .addCase(fetchPushToArchiveAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPushToArchiveAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchPushToArchiveAsync.fulfilled, (state) => {
                state.status = 'idle';
            })
            // land pull from archvie
            .addCase(fetchPullFromArchiveAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPullFromArchiveAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchPullFromArchiveAsync.fulfilled, (state) => {
                state.status = 'idle';
            })
    },
    reducers: {
        clearLand: (state => {
            state.entity = landInitialState;
        }),
    }
})

export const { clearLand } = landSlice.actions;
export const getLand = (state: RootState) => state.land.entity;
export const getWaterTypeList = (state: RootState) => state.land.waterTypeList;

export default landSlice.reducer