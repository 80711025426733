import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAddClient, fetchDeleteClient, fetchGetClient, fetchUpdateClient } from '../../../api/client-api';


export const fetchAddClientAsync = createAsyncThunk(
    "client/fetchAddClientAsync",
    fetchAddClient
);

export const fetchGetClientAsync = createAsyncThunk(
    "client/fetchGetClientAsync",
    fetchGetClient
);

export const fetchUpdateClientAsync = createAsyncThunk(
    "client/fetchUpdateClientAsync",
    fetchUpdateClient
);

export const fetchDeleteClientAsync = createAsyncThunk(
    "client/fetchDeleteClientAsync",
    fetchDeleteClient
);

const clientInitialState = {
    fio: '',
    phone: '',
    email: '',
    description: '',
    clientTypeId: 0,
    isActive: true
}

const initialState = {
    entity: clientInitialState,
    clientTypes: [],
    status: 'idle'
}

export const clientSlice = createSlice({
    name: 'client',
    initialState,
    extraReducers: (builder) => {
        builder
            // add client
            .addCase(fetchAddClientAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAddClientAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchAddClientAsync.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            // get client
            .addCase(fetchGetClientAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetClientAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetClientAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entity = action.payload.data;
                }
            })
            // udpate client
            .addCase(fetchUpdateClientAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUpdateClientAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchUpdateClientAsync.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(fetchDeleteClientAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchDeleteClientAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchDeleteClientAsync.fulfilled, (state, action) => {
                state.status = 'idle';
            })
    },
    reducers: {
        clearClient: (state => {
            state.entity = clientInitialState;
        }),
    }
})

export const { clearClient } = clientSlice.actions;
export const getClient = (state) => state.client.entity;
export const getClientLoadStatus = (state) => state.client.status;
export default clientSlice.reducer