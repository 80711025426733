import { TDefault } from '@/model/default-type';
import { fetchGetEstimateRequestTypeList } from '@api/estimate-request-type-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';


export const fetchGetEstimateRequestTypeListAsync = createAsyncThunk(
    "estimateRequestTypetList/fetchGetEstimateRequestTypeListAsync",
    fetchGetEstimateRequestTypeList
);

export type TEstimateRequest = TDefault & { shortName: string; isHidden: boolean }

const initialState: { count: number; entities: Array<TEstimateRequest>; status: string; } = {
    count: 0,
    entities: [],
    status: 'idle'
}

export const estimateRequestListSlice = createSlice({
    name: 'estimateRequestTypetList',
    initialState,
    extraReducers: (builder) => {
        builder
            // get list
            .addCase(fetchGetEstimateRequestTypeListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetEstimateRequestTypeListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetEstimateRequestTypeListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data;
                }
            })
    },
    reducers: {
        clearEstimateRequestTypeList: (state => {
            state.entities = [];
        }),
    }
})

export const { clearEstimateRequestTypeList } = estimateRequestListSlice.actions;
export const getEstimateRequestTypeList = (state: RootState) => state.estimateRequestTypetList.entities;


export default estimateRequestListSlice.reducer