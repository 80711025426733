import { fetchAddComment } from '@/api/comment-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


export const fetchAddCommentAsync = createAsyncThunk(
    "comment/fetchAddCommentAsync",
    fetchAddComment
);

const initialState: { description: string; open: boolean; status: string } = {
    description: '',
    open: false,
    status: 'idle'
}

const changeDataRequests = [
    fetchAddCommentAsync,
]

export const activityCommentSlice = createSlice({
    name: 'activityComment',
    initialState,
    extraReducers: (builder) => {

        changeDataRequests.forEach(req => {
            builder
                .addCase(req.pending, (state) => {
                    state.status = 'loading';
                })
                .addCase(req.rejected, (state) => {
                    state.status = 'idle';
                })
                .addCase(req.fulfilled, (state, action) => {
                    state.status = 'idle';
                })
        })
    },
    reducers: {
    }
})

export default activityCommentSlice.reducer