import { requestWithAuth } from './index'

const USER_API = '/user'

export function fetchAddUser(data) {
    return requestWithAuth({ url: USER_API + '/add', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchGetUser(id) {
    return requestWithAuth({ url: USER_API + '/get?id=' + id, options: { method: 'GET' } })
}

export function fetchDeleteUser(id) {
    return requestWithAuth({ url: USER_API + '/delete?id=' + id, options: { method: 'GET' } })
}

export function fetchUpdateUser(data) {
    return requestWithAuth({ url: USER_API + '/update', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchGetUserList() {
    return requestWithAuth({ url: USER_API + '/get-list ', options: { method: 'GET' } })
}

export function fetchGetUserTypes() {
    return requestWithAuth({ url: USER_API + '/get-user-types', options: { method: 'GET' } })
}

export function fetchGetRoleList() {
    return requestWithAuth({ url: USER_API + '/get-role-list ', options: { method: 'GET' } })
}
