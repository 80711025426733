import { RootState } from '@/services';
import { createSlice } from '@reduxjs/toolkit';

const initialState: { description: string; open: boolean } = {
    description: '',
    open: false,
}

export const activityDescriptionSlice = createSlice({
    name: 'activityDescription',
    initialState,
    reducers: {
        setActivityDescription: ((state, action) => {
            state.description = action.payload;
        }),
        setIsActivityDescriptionOpen: ((state, action) => {
            state.open = action.payload;
        }),
        clearActivityDescription: (state => {
            state.description = '';
        }),
    }
})

export const { clearActivityDescription, setIsActivityDescriptionOpen, setActivityDescription } = activityDescriptionSlice.actions;
export const getActivityDescription = (state: RootState) => state.activityDescription.description;
export const getIsActivityDescriptionOpen = (state: RootState) => state.activityDescription.open;

export default activityDescriptionSlice.reducer