import { requestWithAuth } from "."

const AGENCY_API = '/agency'

export function fetchGetAgencyList() {
    return requestWithAuth({
        url: AGENCY_API + '/get-list', options: { method: 'GET' }
    })
}

export function fetchGetAgency(id) {
    return requestWithAuth({
        url: AGENCY_API + '/get?id=' + id, options: { method: 'GET' }
    })
}

export function fetchAddAgency(data) {
    return requestWithAuth({ url: AGENCY_API + '/add', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchUpdateAgency(data) {
    return requestWithAuth({ url: AGENCY_API + '/update', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

// agent

export function fetchAddAgent(data) {
    return requestWithAuth({ url: AGENCY_API + '/agent-add', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchUpdateAgent(data) {
    return requestWithAuth({ url: AGENCY_API + '/agent-update', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchGetAgent(id) {
    return requestWithAuth({ url: AGENCY_API + '/agent-get?id=' + id, options: { method: 'GET' } })
}