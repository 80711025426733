import { requestWithAuth } from "."

const SPECIALIST_API = '/specialist'

export function fetchAddSpecialist(data) {
    return requestWithAuth({ url: SPECIALIST_API + '/add', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchUpdateSpecialist(data) {
    return requestWithAuth({ url: SPECIALIST_API + '/update', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchGetSpecialist(id) {
    return requestWithAuth({ url: SPECIALIST_API + '/get?id=' + id, options: { method: 'GET' } })
}

export function fetchGetSpecialistByUserId(userId) {
    return requestWithAuth({ url: SPECIALIST_API + '/get-by-user?id=' + userId, options: { method: 'GET' } })
}

export function fetchGetSpecialistList() {
    return requestWithAuth({ url: SPECIALIST_API + '/get-list ', options: { method: 'GET' } })
}
