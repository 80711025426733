import { requestWithAuth } from "."

const BANK_API = '/bank'

export function fetchGetBankList() {
    return requestWithAuth({
        url: BANK_API + '/get-list', options: { method: 'GET' }
    })
}

export function fetchGetBank(id) {
    return requestWithAuth({
        url: BANK_API + '/get?id=' + id, options: { method: 'GET' }
    })
}

export function fetchAddBank(data) {
    return requestWithAuth({ url: BANK_API + '/add', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchUpdateBank(data) {
    return requestWithAuth({ url: BANK_API + '/update', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}