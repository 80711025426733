import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchGetLandSearchList } from '../../../api/land-search-api';
import { TLandSearch } from '../../../model/land-model/land-search-type';


export const fetchGetLandSearchListAsync = createAsyncThunk(
    "landSearchList/fetchGetLandSearchListAsync",
    fetchGetLandSearchList
);

const initialState: { entities: Array<TLandSearch>; status: string; } = {
    entities: [],
    status: 'idle'
}

export const landSearchListSlice = createSlice({
    name: 'landSearchList',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchGetLandSearchListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetLandSearchListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetLandSearchListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data;
                }
            })
    },
    reducers: {

    }
})


export const getLandSearchList = (state: RootState) => state.landSearchList.entities;

export default landSearchListSlice.reducer