import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAddBank, fetchGetBank, fetchUpdateBank } from '../../../api/bank-api';


export const fetchAddBankAsync = createAsyncThunk(
    "bank/fetchAddBankAsync",
    fetchAddBank
);

export const fetchUpdateBankAsync = createAsyncThunk(
    "bank/fetchUpdateBankAsync",
    fetchUpdateBank
);

export const fetchGetBankAsync = createAsyncThunk(
    "bank/fetchGetBankAsync",
    fetchGetBank
);


const initialState = {
    entity: {},
    status: 'idle'
}

export const bankSlice = createSlice({
    name: 'bank',
    initialState,
    extraReducers: (builder) => {
        builder
            // add bank
            .addCase(fetchAddBankAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAddBankAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchAddBankAsync.fulfilled, (state) => {
                state.status = 'idle';
            })
            // update bank
            .addCase(fetchUpdateBankAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUpdateBankAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchUpdateBankAsync.fulfilled, (state) => {
                state.status = 'idle';
            })
            // get bank list
            .addCase(fetchGetBankAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetBankAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetBankAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entity = action.payload.data;
                }
            })
    },
    reducers: {
        clearBank: (state => {
            state.entity = {};
        }),
    }
})

export const { clearBank } = bankSlice.actions;
export const getBank = (state) => state.bank.entity;

export default bankSlice.reducer