import { Typography } from '@mui/material';

export default function InfoField({ title, text, style }: { title: string, text: any; style?: any }) {

    return (
        <div style={{ margin: '0 ', ...style }}>
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                {title}
            </Typography>
            <Typography variant="body2" gutterBottom sx={{ marginLeft: '10px' }} style={{ whiteSpace: 'pre-line' }}>
                {text || '-'}
            </Typography>
        </div>
    );
}