import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from '@mui/material';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from '../../hooks/hooks';
import { clearErrorMessage, getErrorMessage } from '../../services/slices/error/errorSlice';


export default function FatchErorrSnackbar() {
  const [open, setOpen] = React.useState(false);
  const message = useSelector(getErrorMessage)

  const dispatch = useDispatch();

  const [state, setState] = React.useState<{
    open: boolean;
    Transition: React.ComponentType<
      TransitionProps & {
        children: React.ReactElement<any, any>;
      }
    >;
  }>({
    open: false,
    Transition: Fade,
  });

  React.useEffect(() => {
    if (message) {
      setOpen(true);
      setState({
        ...state,
        open: true,
      });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message])

  const saveToClipboard = () => navigator.clipboard.writeText(message || 'Ошибка')

  const action = (
    <Button size="small" onClick={saveToClipboard}>
      <ContentCopyIcon fontSize='small' />
    </Button>
  );

  return (
    <div>
      <Snackbar
        open={open}
        onClose={() => { dispatch(clearErrorMessage()); setOpen(false) }}
        TransitionComponent={props => <Slide {...props} direction="up" />}
        message={message}
        key={state.Transition.name}
      // action={action}
      />
    </div>
  );
}