import { getCookie } from '../utils/coockie.js';

export let HOST = ''

// local
// HOST = 'http://localhost:5001'

// test
// HOST = 'http://80.78.242.169'

// test selectel
// HOST = 'http://87.228.16.162:5001'

// prod - local ui test
// HOST = 'https://framehouser.ru'

export const HOST_MAIN = HOST;
export const HOST_BOT = HOST + ':8556/';
// export const HOST_MAP = HOST + ':9000/';
export const HOST_MAP = 'http://framehouser.ru/map/';

export const HOST_FILE = '/files';
// export const HOST_FILE = 'http://89.111.170.152:5001' + '/static';


const HOST_API = HOST_MAIN + '/api';

const config = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
};

export function request({ url, options }) {
    return fetch(HOST_API + url, { ...config, ...options }).then(res => {
        return res.ok ? res.json() : res.json().then(err => Promise.reject(err));
    });
}

export function requestUpload({ url, options }) {
    return fetch(HOST_API + url, { ...options, headers: { authorization: 'Bearer ' + getCookie('token'), ...options.headers } },).then(res => {
        return res.ok ? res.json() : res.json().then(err => Promise.reject(err));
    });
}

// запрос на загрузку файлов по активности
export function requestUploadActivityFiles({ activityId, formData }) {
    return requestUpload({
        url: `/file/upload?activityId=${activityId}`,
        options: {
            method: 'POST',
            body: formData
        }
    })
}

// запрос на загрузку файлов по комментариям
export function requestUploadCommentFiles({ commentId, formData }) {
    return requestUpload({
        url: `/comment/upload?commentId=${commentId}`,
        options: {
            method: 'POST',
            body: formData
        }
    })
}

export function requestWithAuth({ url, options }) {
    return fetch(
        HOST_API + url,
        {
            ...config,
            ...options,
            headers: { authorization: 'Bearer ' + getCookie('token'), ...options.headers, ...config.headers }
        }
    )
        .then(res => {
            return res.ok ? res.json() : res.json().then(err => {
                console.log('error', err);
                return Promise.reject(err)
            }
            );
        });
}

export function downloadRequestWithAuth({ url, options }) {
    return fetch(
        HOST_API + url,
        {
            ...config,
            ...options,
            headers: {
                authorization: 'Bearer ' + getCookie('token'),
                ...config.headers,
                // ...options.headers,
            }
        }
    )
        .then(res => {
            return res.ok ? res : res.json().then(err => {
                console.log('error', err);
                return Promise.reject(err)
            }
            );
        });
}