import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchGetRoleList } from '../../../api/user-api';
import { TDefault } from '../../../model/default-type';


export const fetchGetRoleListAsync = createAsyncThunk(
    "roleList/fetchGetRoleListAsync",
    fetchGetRoleList
);

const initialState: { entities: Array<TDefault>; status: string; } = {
    entities: [],
    status: 'idle'
}

export const roleListSlice = createSlice({
    name: 'roleList',
    initialState,
    extraReducers: (builder) => {
        builder
            // get all persons
            .addCase(fetchGetRoleListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetRoleListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetRoleListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data;
                }
            })
    },
    reducers: {}
})


export const getroleList = (state: RootState) => state.roleList.entities;

export default roleListSlice.reducer