import {
    fetchAddEstimateRequest,
    fetchCancelEstimateRequest,
    fetchDoneEstimateRequest,
    fetchGetEstimateRequest,
    fetchGetTypeListEstimateRequest,
    fetchReturnForClarificationEstimateRequest,
    fetchReturnToWorkEstimateRequest,
    fetchSendToChatEstimateRequest,
    fetchTakeForClarificationEstimateRequest,
    fetchTakeToWorkEstimateRequest,
    fetchToVerificationEstimateRequest,
    fetchUpdateEstimateRequest,
    fetchVerificationRequest
} from '@api/estimate-request-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


export const fetchAddEstimateRequestAsync = createAsyncThunk(
    "estimateRequest/fetchAddEstimateRequestAsync",
    fetchAddEstimateRequest
);

export const fetchUpdateEstimateRequestAsync = createAsyncThunk(
    "estimateRequest/fetchUpdateEstimateRequestAsync",
    fetchUpdateEstimateRequest
);

export const fetchTakeToWorkEstimateRequestAsync = createAsyncThunk(
    "estimateRequest/fetchTakeToWorkEstimateRequestAsync",
    fetchTakeToWorkEstimateRequest
);

export const fetchReturnToWorkEstimateRequestAsync = createAsyncThunk(
    "estimateRequest/fetchReturnToWorkEstimateRequestAsync",
    fetchReturnToWorkEstimateRequest
);

export const fetchReturnForClarificationEstimateRequestAsync = createAsyncThunk(
    "estimateRequest/fetchReturnForClarificationEstimateRequestAsync",
    fetchReturnForClarificationEstimateRequest
);

export const fetchTakeForClarificationEstimateRequestAsync = createAsyncThunk(
    "estimateRequest/fetchTakeForClarificationEstimateRequestAsync",
    fetchTakeForClarificationEstimateRequest
);

export const fetchToVerificationEstimateRequestAsync = createAsyncThunk(
    "estimateRequest/fetchToVerificationEstimateRequestAsync",
    fetchToVerificationEstimateRequest
);

export const fetchVerificationRequestAsync = createAsyncThunk(
    "estimateRequest/fetchVerificationRequestAsync",
    fetchVerificationRequest
);

export const fetchGetEstimateRequestAsync = createAsyncThunk(
    "estimateRequest/fetchGetEstimateRequestAsync",
    fetchGetEstimateRequest
);

export const fetchGetTypeListEstimateRequestAsync = createAsyncThunk(
    "estimateRequest/fetchGetTypeListEstimateRequestAsync",
    fetchGetTypeListEstimateRequest
);

export const fetchDoneEstimateRequestAsync = createAsyncThunk(
    "estimateRequest/fetchDoneEstimateRequestAsync",
    fetchDoneEstimateRequest
);

export const fetchSendToChatEstimateRequestAsync = createAsyncThunk(
    "estimateRequest/fetchSendToChatEstimateRequestAsync",
    fetchSendToChatEstimateRequest
);

export const fetchCancelEstimateRequestAsync = createAsyncThunk(
    "estimateRequest/fetchCancelEstimateRequestAsync",
    fetchCancelEstimateRequest
);

const initialState = {
    entity: {},
    status: 'idle',
    estimateRequestTypeList: []
}

const changeDataRequests = [
    fetchAddEstimateRequestAsync,
    fetchUpdateEstimateRequestAsync,
    fetchTakeToWorkEstimateRequestAsync,
    fetchReturnForClarificationEstimateRequestAsync,
    fetchTakeForClarificationEstimateRequestAsync,
    fetchReturnToWorkEstimateRequestAsync,
    fetchToVerificationEstimateRequestAsync,
    fetchVerificationRequestAsync,
    fetchDoneEstimateRequestAsync,
    fetchSendToChatEstimateRequestAsync,
    fetchCancelEstimateRequestAsync
]

export const estimateRequestSlice = createSlice({
    name: 'estimateRequest',
    initialState,
    extraReducers: (builder) => {
        changeDataRequests.forEach(req => {
            builder
                .addCase(req.pending, (state) => {
                    state.status = 'loading';
                })
                .addCase(req.rejected, (state) => {
                    state.status = 'idle';
                })
                .addCase(req.fulfilled, (state) => {
                    state.status = 'idle';
                })
        })
        builder
            // get estimate request
            .addCase(fetchGetEstimateRequestAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetEstimateRequestAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetEstimateRequestAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entity = action.payload.data;
                }
            })
    },
    reducers: {
        clearEstimateRequest: (state => {
            state.entity = {};
        }),
        changeEstimateRequestType: ((state, action) => {
            state.entity.estimateRequestType = action.payload;
        }),
    }
})

export const { clearEstimateRequest, changeEstimateRequestType } = estimateRequestSlice.actions;
export const getEstimateRequest = (state) => state.estimateRequest.entity;

export default estimateRequestSlice.reducer