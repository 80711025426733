import { fetchAddEntity, fetchDeleteEntity, fetchFindAndCount, fetchGetEntity, fetchUpdateEntity } from "./common"
import { downloadRequestWithAuth, requestWithAuth } from "./index.js"

const CLIENT_API = '/client'

export function fetchAddClient(data) {
    return fetchAddEntity(CLIENT_API, data)
}

export function fetchGetClient(id) {
    return fetchGetEntity(CLIENT_API, id)
}

export function fetchUpdateClient(data) {
    return fetchUpdateEntity(CLIENT_API, data)
}

export function fetchDeleteClient(id) {
    return fetchDeleteEntity(CLIENT_API, id)
}

export function fetchClientFindAndCount(data) {
    return fetchFindAndCount(CLIENT_API, data)
}

export function fetchDownloadClientListCSV() {
    return downloadRequestWithAuth({
        url: CLIENT_API + '/download-csv', options: {
            method: 'GET',
            headers: {
                'Content-Type': 'application/csv',
            },
        }
    })
}
export function fetchDownloadClientListXLSX() {
    return downloadRequestWithAuth({
        url: CLIENT_API + '/download-xlsx', options: {
            method: 'GET',
            headers: {
                'Content-Type': 'application/xlsx',
            },
        }
    })
}


// deprecated
export function fetchGetClientList() {
    return requestWithAuth({ url: CLIENT_API + '/get-list', options: { method: 'GET' } })
}


// dictionary

export function fetchGetEntryPointList() {
    return requestWithAuth({ url: CLIENT_API + '/get-entry-point-list', options: { method: 'GET' } })
}

export function fetchGetEstimateStatusList() {
    return requestWithAuth({ url: CLIENT_API + '/get-estimate-status-list', options: { method: 'GET' } })
}

export function fetchGetMortgageStatusList() {
    return requestWithAuth({ url: CLIENT_API + '/get-mortgage-status-list', options: { method: 'GET' } })
}

export function fetchGetPlotStatusList() {
    return requestWithAuth({ url: CLIENT_API + '/get-plot-status-list', options: { method: 'GET' } })
}

export function fetchGetProjectStatusList() {
    return requestWithAuth({ url: CLIENT_API + '/get-project-status-list', options: { method: 'GET' } })
}