import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchGetLandInspectionList, fetchGetLandInspectionListByClient, fetchGetLandInspectionListFromLandOffers } from '../../../api/land-inspection-api';
import { TLandInspection } from '../../../model/land-model/land-inspection-type';


export const fetchGetLandInsectionListAsync = createAsyncThunk(
    "landInsectionList/fetchGetLandInsectionListAsync",
    fetchGetLandInspectionList
);

export const fetchGetLandInspectionListByClientAsync = createAsyncThunk(
    "landInsectionList/fetchGetLandInspectionListByClientAsync",
    fetchGetLandInspectionListByClient
);

export const fetchGetLandInspectionListFromLandOffersAsync = createAsyncThunk(
    "landInsectionList/fetchGetLandInspectionListFromLandOffersAsync",
    fetchGetLandInspectionListFromLandOffers
);

const initialState: { entities: Array<TLandInspection>; status: string; } = {
    entities: [],
    status: 'idle'
}

export const landInspectionListSlice = createSlice({
    name: 'landInsectionList',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchGetLandInsectionListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetLandInsectionListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetLandInsectionListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities =
                        action.payload.data.map((landInspection: TLandInspection) => ({
                            ...landInspection,
                            land: { ...landInspection.land, landDrillingList: landInspection?.land?.land_drillings }
                        }));
                }
            })
            .addCase(fetchGetLandInspectionListByClientAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetLandInspectionListByClientAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetLandInspectionListByClientAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities =
                        action.payload.data.map((landInspection: TLandInspection) => ({
                            ...landInspection,
                            land: { ...landInspection.land, landDrillingList: landInspection?.land?.land_drillings }
                        }));
                }
            })
            .addCase(fetchGetLandInspectionListFromLandOffersAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetLandInspectionListFromLandOffersAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetLandInspectionListFromLandOffersAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities =
                        action.payload.data.map((landInspection: TLandInspection) => ({
                            ...landInspection,
                            land: { ...landInspection.land, landDrillingList: landInspection?.land?.land_drillings }
                        }));
                }
            })
    },
    reducers: {
        clearLandInspectionList: (state => {
            state.entities = [];
        }),
        filterLandByStatus: (state, action) => {
            state.entities = state.entities.filter(landInspection =>
                landInspection.activity?.status?.name === action.payload.statusName
            );
        },
    }
})

export const { clearLandInspectionList, filterLandByStatus } = landInspectionListSlice.actions;
export const getLandInsectionList = (state: RootState) => state.landInsectionList.entities;
export const getLandInsectionListByApplicationEndStatus = (state: RootState) => state.landInsectionList.status

export default landInspectionListSlice.reducer