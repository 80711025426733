import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchAddLandInspection, fetchCheckLandInspectionByCadastre, fetchDeleteLandInspection, fetchGetLandInspection, fetchLandInspectionToDone, fetchUpdateLandInspection, fetchUpdateLandInspectionResult } from '../../../api/land-inspection-api';
import { TLandInspection } from '../../../model/land-model/land-inspection-type';


export const fetchAddLandInspectionAsync = createAsyncThunk(
    "landInspection/fetchAddLandInspectionAsync",
    fetchAddLandInspection
);

export const fetchGetLandInspectionAsync = createAsyncThunk(
    "landInspection/fetchGetLandInspectionAsync",
    fetchGetLandInspection
);

// Редактирование Новой заявки автором
export const fetchUpdateLandInspectionAsync = createAsyncThunk(
    "landInspection/fetchUpdateLandInspectionAsync",
    fetchUpdateLandInspection
);

// Перевод заявки на проверку участка в статус "Выполнена"
export const fetchLandInspectionApplicationToDoneAsync = createAsyncThunk(
    "landInspection/done",
    fetchLandInspectionToDone
);

export const fetchDeleteLandInspectionAsync = createAsyncThunk(
    "landInspection/fetchDeleteLandInspectionAsync",
    fetchDeleteLandInspection
);

export const fetchCheckLandInspectionByCadastreAsync = createAsyncThunk(
    "landInspection/fetchCheckLandInspectionByCadastreAsync",
    fetchCheckLandInspectionByCadastre
);

export const fetchUpdateLandInspectionResultAsync = createAsyncThunk(
    "landInspection/fetchUpdateLandInspectionResultAsync",
    fetchUpdateLandInspectionResult
);

const landInspectionInitialState: TLandInspection = {
    authorId: null,
    clientId: null,
    result: '',
    description: '',
    landId: 0,
}
const checkCadastreInitialState: { data: null, id: string, msg: string } = {
    id: '',
    msg: '',
    data: null,
}

export const error: { message?: string } = {
    message: ''
}

const initialState = {
    entity: landInspectionInitialState,
    landinspectionTypes: [],
    checkCadastre: checkCadastreInitialState,
    loadStatus: 'idle',
    error
}


export const landInspectionSlice = createSlice({
    name: 'landInspection',
    initialState,
    extraReducers: (builder) => {
        builder
            // add 
            .addCase(fetchAddLandInspectionAsync.pending, (state) => {
                state.loadStatus = 'loading';
                state.error = { message: '' }
            })
            .addCase(fetchAddLandInspectionAsync.rejected, (state, action) => {
                state.error = action.error
                state.loadStatus = 'idle';
            })
            .addCase(fetchAddLandInspectionAsync.fulfilled, (state) => {
                state.loadStatus = 'idle';
            })
            // get 
            .addCase(fetchGetLandInspectionAsync.pending, (state) => {
                state.loadStatus = 'loading';
            })
            .addCase(fetchGetLandInspectionAsync.rejected, (state, action) => {
                state.loadStatus = 'idle';
                state.error = action.error
            })
            .addCase(fetchGetLandInspectionAsync.fulfilled, (state, action) => {
                state.loadStatus = 'idle';

                if (action.payload.success) {
                    state.entity = { ...action.payload.data, fileList: action.payload.fileList }
                }
            })
            // udpate 
            .addCase(fetchUpdateLandInspectionAsync.pending, (state) => {
                state.loadStatus = 'loading';
            })
            .addCase(fetchUpdateLandInspectionAsync.rejected, (state, action) => {
                state.loadStatus = 'idle';
                state.error = action.error
            })
            .addCase(fetchUpdateLandInspectionAsync.fulfilled, (state) => {
                state.loadStatus = 'idle';
            })
            // delete
            .addCase(fetchDeleteLandInspectionAsync.pending, (state) => {
                state.loadStatus = 'loading';
            })
            .addCase(fetchDeleteLandInspectionAsync.rejected, (state, action) => {
                state.loadStatus = 'idle';
                state.error = action.error
            })
            .addCase(fetchDeleteLandInspectionAsync.fulfilled, (state) => {
                state.loadStatus = 'idle';
            })
            // check
            .addCase(fetchCheckLandInspectionByCadastreAsync.pending, (state) => {
                state.loadStatus = 'loading';
            })
            .addCase(fetchCheckLandInspectionByCadastreAsync.rejected, (state, action) => {
                state.error = action.error
                state.loadStatus = 'idle';
            })
            .addCase(fetchCheckLandInspectionByCadastreAsync.fulfilled, (state, action) => {
                state.loadStatus = 'idle';
                if (action.payload.success) {
                    state.checkCadastre = { ...action.payload.data, msg: action.payload.msg }
                }
            })
            .addCase(fetchUpdateLandInspectionResultAsync.pending, (state) => {
                state.loadStatus = 'loading';
            })
            .addCase(fetchUpdateLandInspectionResultAsync.rejected, (state, action) => {
                state.loadStatus = 'idle';
                state.error = action.error
            })
            .addCase(fetchUpdateLandInspectionResultAsync.fulfilled, (state) => {
                state.loadStatus = 'idle';
            })
    },
    reducers: {
        clearLandInspection: (state => {
            state.entity = landInspectionInitialState;
        }),
        clearCheckCadastre: (state => {
            state.checkCadastre = checkCadastreInitialState;
        }),
        addLandInspectionAuthor: ((state, action) => {
            state.entity.authorId = action.payload.authorId;
            state.entity.author = action.payload.author;
        }),
        addLandInspectionClient: ((state, action) => {
            state.entity.clientId = action.payload.clientId;
            // state.entity.land.client = action.payload.client;
        }),
    }
})

export const { clearLandInspection, clearCheckCadastre, addLandInspectionClient } = landInspectionSlice.actions;
export const getLandInspection = (state: RootState) => state.landInspection.entity;
export const getCheckCadastre = (state: RootState) => state.landInspection.checkCadastre;
export const getLoadStatus = (state: RootState) => state.landInspection.loadStatus;
export const getError = (state: RootState) => state.landInspection.error;
export default landInspectionSlice.reducer