import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchGetActivityType, fetchGetActivityTypeSlaStatusList, fetchUpdateActivityTypeStatusSla } from '../../../api/activity-api';
import { TActivitySlaStatusType } from '../../../model/activity-model/activity-sla-status-type';



export const fetchGetActivityTypeAsync = createAsyncThunk(
    "activityType/fetchGetActivityTypeAsync",
    fetchGetActivityType
);

export const fetchGetActivityTypeSlaStatusListAsync = createAsyncThunk(
    "activityType/fetchGetSlaStatusListAsync",
    fetchGetActivityTypeSlaStatusList
);
export const fetchUpdateActivityTypeStatusSlaAsync = createAsyncThunk(
    "activityType/fetchUpdateActivityTypeStatusSlaAsync",
    fetchUpdateActivityTypeStatusSla
);

const activityTypeInitialState: { name: string; text: string } = {
    name: '',
    text: '',
}

const initialState: { entity: { name: string; text: string }; slaStatusList: Array<TActivitySlaStatusType>; status: string } = {
    entity: activityTypeInitialState,
    slaStatusList: [],
    status: 'idle'
}

export const activityTypeSlice = createSlice({
    name: 'activityType',
    initialState,
    extraReducers: (builder) => {
        builder
            // get activity type
            .addCase(fetchGetActivityTypeAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetActivityTypeAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetActivityTypeAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entity = action.payload.data;
                }
            })
            // get activity type status list
            .addCase(fetchGetActivityTypeSlaStatusListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetActivityTypeSlaStatusListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetActivityTypeSlaStatusListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.slaStatusList = action.payload.data;
                }
            })
            // update activity type status sla
            .addCase(fetchUpdateActivityTypeStatusSlaAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUpdateActivityTypeStatusSlaAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchUpdateActivityTypeStatusSlaAsync.fulfilled, (state, action) => {
                state.status = 'idle';
            })
    },
    reducers: {
        clearActivityType: (state => {
            state.entity = activityTypeInitialState;
        }),
        clearActivityTypeSlaStatuList: (state => {
            state.slaStatusList = [];
        }),
    }
})

export const { clearActivityType, clearActivityTypeSlaStatuList } = activityTypeSlice.actions;
export const getActivityTypeSlaStatusList = (state: RootState) => state.activityType.slaStatusList;
export const getActivityType = (state: RootState) => state.activityType.entity;

export default activityTypeSlice.reducer