import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { getUserData, signin, updateUserRoles } from '../../../api/auth-api';
import { TDefault } from '../../../model/default-type';
import { TPerson } from '../../../model/person-type';
import { deleteCookie, setCookie } from '../../../utils/coockie';


export const fetchLoginAsync = createAsyncThunk(
    "auth/fetchLoginAsync",
    signin
);

export const fetchGetUserDataAsync = createAsyncThunk(
    "auth/fetchGetUserDataAsync",
    getUserData
);

export const fetchUpdateUserRoleAsync = createAsyncThunk(
    "auth/fetchUpdateUserRoleAsync",
    updateUserRoles
);

type TUser = {
    id?: number,
    email: string;
    password: string;
    person?: TPerson;
    roles: TDefault[];
}

const userInitialState: TUser = {
    email: '',
    password: '',
    roles: []
}

const initialState: { user: TUser, status: string } = {
    user: userInitialState,
    status: 'idle'
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    extraReducers: (builder) => {
        builder
            // login
            .addCase(fetchLoginAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchLoginAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchLoginAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                console.log(action.payload);

                if (action.payload.success) {
                    setCookie('token', action.payload.token, { path: '/' });

                    state.user.email = action.payload.email;
                }
            })

            // update use roles
            .addCase(fetchUpdateUserRoleAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUpdateUserRoleAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchUpdateUserRoleAsync.fulfilled, (state, action) => {
                state.status = 'idle';
            })

            // get user data
            .addCase(fetchGetUserDataAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetUserDataAsync.rejected, (state) => {
                state.status = 'idle';
                state.user = userInitialState
            })
            .addCase(fetchGetUserDataAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success && action.payload.profile) {
                    state.user.email = action.payload.profile.person.email;
                    state.user.roles = action.payload.profile.roles
                    state.user.id = action.payload.profile.id;
                    state.user.person = action.payload.profile.person
                }
            })
    },
    reducers: {
        signout: (state) => {
            deleteCookie('token')
            state.user = userInitialState
        },
    }
})

export const getAuthUser = (state: RootState) => state.auth.user;
export const getAuthUserRoles = (state: RootState) => state.auth.user.roles;
export const getLoadStatus = (state: RootState) => state.auth.status;

export const { signout } = authSlice.actions;

export default authSlice.reducer