import { requestWithAuth } from './index'

const PERSON_API = '/person'

export function fetchAddPerson(data) {
    return requestWithAuth({ url: PERSON_API + '/add', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchGetPersonById(id) {
    return requestWithAuth({ url: PERSON_API + '/get?id=' + id, options: { method: 'GET' } })
}

export function fetchDeletePerson(id) {
    return requestWithAuth({ url: PERSON_API + '/delete?id=' + id, options: { method: 'GET' } })
}

export function fetchUpdatePersonById(data) {
    return requestWithAuth({ url: PERSON_API + '/update', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchGetAllPerson() {
    return requestWithAuth({ url: PERSON_API + '/get-list', options: { method: 'GET' } })
}

export function fetchGetPersonTypes() {
    return requestWithAuth({ url: PERSON_API + '/get-person-types', options: { method: 'GET' } })
}

export function fetchGetPersonEntryPoints() {
    return requestWithAuth({ url: PERSON_API + '/getPersonEntryPoints', options: { method: 'GET' } })
}

export function fetchGetPersonListByType(id) {
    return requestWithAuth({ url: PERSON_API + '/get-list-by-person-type?personTypeId=' + id, options: { method: 'GET' } })
}

export function fetchCheckPhone(phone) {
    return requestWithAuth({ url: PERSON_API + '/check-phone?phone=' + phone, options: { method: 'GET' } })
}