import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchGetHouseList } from '../../../api/house-api';
import { THouse } from '../../../model/house-type';


export const fetchGetHouseListAsync = createAsyncThunk(
    "houseList/fetchGetHouseListAsync",
    fetchGetHouseList
);

const initialState: { entities: Array<THouse>; status: string; } = {
    entities: [],
    status: 'idle'
}

export const houseListSlice = createSlice({
    name: 'houseList',
    initialState,
    extraReducers: (builder) => {
        builder
            // get house
            .addCase(fetchGetHouseListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetHouseListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetHouseListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data;
                }
            })
    },
    reducers: {
        clearHouseList: (state => {
            state.entities = [];
        }),
    }
})

export const { clearHouseList } = houseListSlice.actions;
export const getHouseList = (state: RootState) => state.houseList.entities;

export default houseListSlice.reducer