import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAddUser, fetchGetUser, fetchUpdateUser } from '../../../api/user-api';
import { RootState } from '../..';
import { TUser } from '../../../model/user-type';
import { TDefault } from '../../../model/default-type';
import { updateUserRoles } from '../../../api/auth-api';


export const fetchAddUserAsync = createAsyncThunk(
    "user/fetchAddUserAsync",
    fetchAddUser
);

export const fetchUpdateUserAsync = createAsyncThunk(
    "user/fetchUpdateUserAsync",
    fetchUpdateUser
);

export const fetchGetUserAsync = createAsyncThunk(
    "user/fetchGetUserAsync",
    fetchGetUser
);

export const fetchUpdateUserRoleAsync = createAsyncThunk(
    "auth/fetchUpdateUserRoleAsync",
    updateUserRoles
);

const userInitialState: TUser = {
    roles: [],
    description: ''
}

const initialState: { entity: TUser; roles: TDefault[]; status: string } = {
    entity: userInitialState,
    roles: [],
    status: 'idle'
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: (builder) => {
        builder
            // get user
            .addCase(fetchGetUserAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetUserAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetUserAsync.fulfilled, (state, action) => {

                if (action.payload.success) {
                    state.entity = action.payload.user;
                }
                state.status = 'idle';
            })
            // add user
            .addCase(fetchAddUserAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAddUserAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchAddUserAsync.fulfilled, (state) => {
                state.status = 'idle';
            })
            // update user
            .addCase(fetchUpdateUserAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUpdateUserAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchUpdateUserAsync.fulfilled, (state) => {
                state.status = 'idle';
            })
            // update use roles
            .addCase(fetchUpdateUserRoleAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUpdateUserRoleAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchUpdateUserRoleAsync.fulfilled, (state) => {
                state.status = 'idle';
            })
    },
    reducers: {
        clearUser: (state => {
            state.entity = userInitialState;
        }),
    }
})

export const { clearUser } = userSlice.actions;

export const getUser = (state: RootState) => state.user.entity;

export default userSlice.reducer