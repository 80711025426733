import { requestWithAuth } from "."

const AGENCY_TELEGRAM_API = '/agency/telegram'

export function fetchGetAgencyTelegram(agencyId) {
    return requestWithAuth({
        url: AGENCY_TELEGRAM_API + '/get?agencyId=' + agencyId, options: { method: 'GET' }
    })
}

export function fetchUpdateAgencyTelegram(data) {
    return requestWithAuth({ url: AGENCY_TELEGRAM_API + '/update', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}