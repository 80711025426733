import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchClientFindAndCount, fetchGetClientList } from '../../../api/client-api';
import { TClient } from '../../../model/client-type';

export const fetchGetClientListAsync = createAsyncThunk(
    "clientList/fetchGetAllClientAsync",
    fetchGetClientList
);

export const fetchClientFindAndCountAsync = createAsyncThunk(
    "clientList/fetchClientFindAndCountAsync",
    fetchClientFindAndCount
);

const initialState: { count: number; entities: Array<TClient>; status: string; } = {
    count: 0,
    entities: [],
    status: 'idle'
}

export const personsListSlice = createSlice({
    name: 'clientList',
    initialState,
    extraReducers: (builder) => {
        builder
            // get all clients
            .addCase(fetchGetClientListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetClientListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetClientListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data;
                }
            })

            // get clients count
            .addCase(fetchClientFindAndCountAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchClientFindAndCountAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchClientFindAndCountAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.count = action.payload.data.count;
                    state.entities = action.payload.data.rows;
                }
            })
    },
    reducers: {

    }
})


export const getClientList = (state: RootState) => state.clientList.entities;
export const getClientCount = (state: RootState) => state.clientList.count;
export const getClientListLoadStatus = (state: RootState) => state.clientList.status;

export default personsListSlice.reducer