import { fetchGetEstimateList } from '@api/estimate-api';
import { TEstimate } from '@model/estimate-type';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';


export const fetchGetEstimateListAsync = createAsyncThunk(
    "estimateList/fetchGetEstimateListAsync",
    fetchGetEstimateList
);

const initialState: { entities: Array<TEstimate>; status: string; } = {
    entities: [],
    status: 'idle'
}

export const estimateListSlice = createSlice({
    name: 'estimateList',
    initialState,
    extraReducers: (builder) => {
        builder
            // get list
            .addCase(fetchGetEstimateListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetEstimateListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetEstimateListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data;
                }
            })
    },
    reducers: {
        clearEstimateList: (state => {
            state.entities = [];
        }),
    }
})

export const { clearEstimateList } = estimateListSlice.actions;
export const getEstimateList = (state: RootState) => state.estimateList.entities;

export default estimateListSlice.reducer