import { useDispatch } from '@/hooks/hooks';
import Status from '@components/status/status';
import { Box, Divider, Drawer, FormControl, List, ListItem, ListItemButton } from '@mui/material';
import { clearStatusHistoryList, getIsStatusHistoryListOpen, getStatusHistoryList, setIsStatusHistoryListOpen } from '@services/slices/status-history-list-slice';
import { shortDateTime } from '@utils/date';
import * as React from 'react';
import { useSelector } from 'react-redux';
import InfoField from '../info-field/info-field';

export const StatusHistory = () => {
    const dispatch = useDispatch();

    // метод получения списка статусов
    const statusHistoryList = useSelector(getStatusHistoryList)
    const isStatusHistoryListOpen = useSelector(getIsStatusHistoryListOpen)


    const toggleDrawer = () =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
        };

    const onClose = () => {
        dispatch(setIsStatusHistoryListOpen(false))
        dispatch(clearStatusHistoryList())
    }

    const list = () => (
        <Box
            sx={{ width: 600 }}
            role="presentation"
            onClick={toggleDrawer()}
            onKeyDown={toggleDrawer()}
            style={{ marginTop: '100px' }}
        >
            <List>
                {[...statusHistoryList].reverse().map((statusHistory: any) => (
                    <>
                        <ListItem key={statusHistory.id} disablePadding>
                            <ListItemButton sx={{ justifyContent: 'space-between' }}>
                                <div className="author">
                                    {shortDateTime(statusHistory.createdAt)}
                                    {statusHistory?.author?.fio ? ` - ${statusHistory?.author?.fio}` : ''}
                                </div>
                                <Status status={statusHistory.status} />
                            </ListItemButton>
                        </ListItem>
                        {statusHistory?.description &&
                            <FormControl sx={{ ml: 2 }}>
                                <InfoField title="Комментарий" text={statusHistory?.description} />
                            </FormControl>
                        }
                        <Divider />
                    </>
                ))}
            </List>
        </Box>
    );

    return (
        <div >
            <React.Fragment key={'right'} >
                <Drawer
                    anchor={'right'}
                    open={isStatusHistoryListOpen}
                    onClose={onClose}
                >
                    {list()}
                </Drawer>
            </React.Fragment>

        </div>
    );
}