import { TEstimateRequest } from '@/model/estimate-request-type';
import { fetchGetEstimateListRequest, fetchGetEstimateRequestFindAndCount } from '@api/estimate-request-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';


export const fetchGetEstimateRequestListAsync = createAsyncThunk(
    "estimateRequestList/fetchGetEstimateRequestListAsync",
    fetchGetEstimateListRequest
);

export const fetchGetEstimateRequestFindAndCountAsync = createAsyncThunk(
    "estimateRequestList/fetchGetEstimateRequestFindAndCountAsync",
    fetchGetEstimateRequestFindAndCount
);

const initialState: { count: number; entities: Array<TEstimateRequest>; status: string; } = {
    count: 0,
    entities: [],
    status: 'idle'
}

export const estimateRequestListSlice = createSlice({
    name: 'estimateRequestList',
    initialState,
    extraReducers: (builder) => {
        builder
            // get list
            .addCase(fetchGetEstimateRequestListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetEstimateRequestListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetEstimateRequestListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data;
                }
            })

            // get clients count
            .addCase(fetchGetEstimateRequestFindAndCountAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetEstimateRequestFindAndCountAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetEstimateRequestFindAndCountAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.count = action.payload.data.count;
                    state.entities = action.payload.data.rows;
                }
            })
    },
    reducers: {
        clearEstimateRequestList: (state => {
            state.entities = [];
        }),
    }
})

export const { clearEstimateRequestList } = estimateRequestListSlice.actions;
export const getEstimateRequestList = (state: RootState) => state.estimateRequestList.entities;
export const getEstimateCount = (state: RootState) => state.estimateRequestList.count;


export default estimateRequestListSlice.reducer