import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchGetSpecialistList } from '../../../api/specialist-api';
import { RootState } from '../..';
import { TPerson } from '../../../model/person-type';


export const fetchGetSpecialistListAsync = createAsyncThunk(
    "personList/fetchGetSpecialistListAsync",
    fetchGetSpecialistList
);

const initialState: { entities: Array<TPerson>; status: string; } = {
    entities: [],
    status: 'idle'
}

export const specialistListSlice = createSlice({
    name: 'specialistList',
    initialState,
    extraReducers: (builder) => {
        builder
            // get all persons
            .addCase(fetchGetSpecialistListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetSpecialistListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetSpecialistListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data;
                }
            })
    },
    reducers: {

    }
})


export const getSpecialistList = (state: RootState) => state.specialistList.entities;

export default specialistListSlice.reducer