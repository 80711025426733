import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchGetBankList } from '../../../api/bank-api';
import { TBank } from '../../../model/bank-type';


export const fetchGetBankListAsync = createAsyncThunk(
    "bankList/fetchGetBankListAsync",
    fetchGetBankList
);

const initialState: { entities: Array<TBank>; status: string; } = {
    entities: [],
    status: 'idle'
}

export const bankListSlice = createSlice({
    name: 'bankList',
    initialState,
    extraReducers: (builder) => {
        builder
            // get bank
            .addCase(fetchGetBankListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetBankListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetBankListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data;
                }
            })
    },
    reducers: {
        clearBankList: (state => {
            state.entities = [];
        }),
    }
})

export const { clearBankList } = bankListSlice.actions;
export const getBankList = (state: RootState) => state.bankList.entities;

export default bankListSlice.reducer