import { requestWithAuth } from './index'

const LAND_DRILLING_API = '/land-drilling'

export function fetchAddLandDrilling(data) {
    return requestWithAuth({ url: LAND_DRILLING_API + '/add', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchGetLandDrilling(id) {
    return requestWithAuth({ url: LAND_DRILLING_API + '/get?id=' + id, options: { method: 'GET' } })
}

export function fetchCancelLandDrilling(id) {
    return requestWithAuth({ url: LAND_DRILLING_API + '/cancel?id=' + id, options: { method: 'GET' } })
}

export function fetchDeleteLandDrilling(id) {
    return requestWithAuth({ url: LAND_DRILLING_API + '/delete?id=' + id, options: { method: 'GET' } })
}

export function fetchUpdateLandDrilling(data) {
    return requestWithAuth({ url: LAND_DRILLING_API + '/update', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchLandDrillingToWork(data) {
    return requestWithAuth({ url: LAND_DRILLING_API + '/work', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchLandDrillingToEvaluation(data) {
    return requestWithAuth({ url: LAND_DRILLING_API + '/evaluation', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchLandDrillingToDone(data) {
    return requestWithAuth({ url: LAND_DRILLING_API + '/done', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchGetLandDrillingList() {
    return requestWithAuth({ url: LAND_DRILLING_API + '/get-list', options: { method: 'GET' } })
}

export function fetchGetLandDrillingPileTypeList() {
    return requestWithAuth({ url: LAND_DRILLING_API + '/get-pile-type-list', options: { method: 'GET' } })
}