import { requestWithAuth } from "."

const HOUSE_API = '/house'

export function fetchGetHouseList() {
    return requestWithAuth({
        url: HOUSE_API + '/get-list', options: { method: 'GET' }
    })
}

export function fetchGetHouse(id) {
    return requestWithAuth({
        url: HOUSE_API + '/get?id=' + id, options: { method: 'GET' }
    })
}

export function fetchAddHouse(data) {
    return requestWithAuth({ url: HOUSE_API + '/add', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchUpdateHouse(data) {
    return requestWithAuth({ url: HOUSE_API + '/update', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}