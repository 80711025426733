import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAddLandDrilling, fetchCancelLandDrilling, fetchDeleteLandDrilling, fetchGetLandDrilling, fetchLandDrillingToDone, fetchLandDrillingToEvaluation, fetchLandDrillingToWork, fetchUpdateLandDrilling } from '../../../api/land-drilling-api';


export const fetchAddLandDrillingAsync = createAsyncThunk(
    "landDrilling/add",
    fetchAddLandDrilling
);

// Создание заявки на пробное бурение
export const fetchCreateLandDrillingApplicationAsync = createAsyncThunk(
    "landDrilling/create",
    fetchAddLandDrilling
);

// Изменение Новой заявки на пробное бурение, без изменения статуса
export const fetchUpdateLandDrillingAsync = createAsyncThunk(
    "landDrilling/fetchUpdateLandDrillingAsync",
    fetchUpdateLandDrilling
);

// Назначение заявки на пробное бурение на исполнителя и перевод в статус "В работе"
export const fetchLandDrillingApplicationToWorkAsync = createAsyncThunk(
    "landDrilling/work",
    fetchLandDrillingToWork
);

// Назначение заявки на пробное бурение на главного геодезиста и перевод в статус "Оценка"
export const fetchLandDrillingApplicationToEvaluatuionAsync = createAsyncThunk(
    "landDrilling/evaluation",
    fetchLandDrillingToEvaluation
);

// Перевод заявки на пробное бурение в статус "Выполнена"
export const fetchLandDrillingApplicationToDoneAsync = createAsyncThunk(
    "landDrilling/done",
    fetchLandDrillingToDone
);

// Перевод заявки на пробное бурение в статус "Отменена"
export const fetchLandDrillingApplicationInCancelAsync = createAsyncThunk(
    "landDrilling/cancel",
    fetchCancelLandDrilling
);

export const fetchGetLandDrillingAsync = createAsyncThunk(
    "landDrilling/fetchGetLandDrillingAsync",
    fetchGetLandDrilling
);



export const fetchDeleteLandDrillingAsync = createAsyncThunk(
    "landDrilling/fetchDeleteLandDrillingAsync",
    fetchDeleteLandDrilling
);


// два типа запросов { get / get-list } , { add, update, }

const changeDataRequests = [
    fetchAddLandDrillingAsync,
    fetchUpdateLandDrillingAsync,
    fetchLandDrillingApplicationInCancelAsync,
    fetchLandDrillingApplicationToWorkAsync,
    fetchLandDrillingApplicationToEvaluatuionAsync,
    fetchLandDrillingApplicationToDoneAsync
]

const landDrillingInitialState = {
    cadastre: '',
    authorId: null,
    clientId: null,
    author: {},
    client: {},
    result: '',
    description: '',
}

const initialState = {
    entity: landDrillingInitialState,
    landdrillingTypes: [],
    status: 'idle'
}

export const landDrillingSlice = createSlice({
    name: 'landDrilling',
    initialState,
    extraReducers: (builder) => {

        changeDataRequests.forEach(req => {
            builder
                .addCase(req.pending, (state) => {
                    state.status = 'loading';
                })
                .addCase(req.rejected, (state) => {
                    state.status = 'idle';
                })
                .addCase(req.fulfilled, (state, action) => {
                    state.status = 'idle';
                })
        })

        builder
            // get 
            .addCase(fetchGetLandDrillingAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetLandDrillingAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetLandDrillingAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entity = action.payload.data;
                    state.entity = { ...action.payload.data, fileList: action.payload.fileList };
                }
            })
    },
    reducers: {
        clearLandDrilling: (state => {
            state.entity = landDrillingInitialState;
        }),
        addLandDrillingAuthor: ((state, action) => {
            state.entity.authorId = action.payload.authorId;
            state.entity.author = action.payload.author;
        }),
        addLandDrillingClient: ((state, action) => {
            state.entity.clientId = action.payload.clientId;
            state.entity.client = action.payload.client;
        }),
    }
})

export const { clearLandDrilling, addLandDrillingClient } = landDrillingSlice.actions;
export const getLandDrilling = (state) => state.landDrilling.entity;
export default landDrillingSlice.reducer