import { fetchAddEntity, fetchGetEntity, fetchGetList, fetchUpdateEntity } from "./common"


const ESTIMATE_API = '/estimate-request-type'

export function fetchGetEstimateRequestTypeList() {
    return fetchGetList(ESTIMATE_API)
}

export function fetchGetEstimateRequestType(id) {
    return fetchGetEntity(ESTIMATE_API, id)
}

export function fetchAddEstimateRequestType(data) {
    return fetchAddEntity(ESTIMATE_API, data)
}

export function fetchUpdateEstimateRequestType(data) {
    return fetchUpdateEntity(ESTIMATE_API, data)
}