import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchGetPersonListByType } from '../../../api/person-api';
import { TPerson } from '../../../model/person-type';


export const fetchGetPersonListByTypeAsync = createAsyncThunk(
    "managerList/fetchGetPersonListByTypeAsync",
    fetchGetPersonListByType
);

const initialState: { entities: Array<TPerson>; status: string; } = {
    entities: [],
    status: 'idle'
}

export const managerListSlice = createSlice({
    name: 'managerList',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchGetPersonListByTypeAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetPersonListByTypeAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetPersonListByTypeAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data;
                }
            })
    },
    reducers: {

    }
})


export const getManagerList = (state: RootState) => state.managerList.entities;

export default managerListSlice.reducer