import { requestWithAuth } from "."

const ACTIVITY_API = '/activity'


export function fetchGetActivityType(activityTypeId) {
    return requestWithAuth({
        url: ACTIVITY_API + '/get-activity-type?id=' + activityTypeId, options: { method: 'GET' }
    })
}

export function fetchGetActivityTypeList() {
    return requestWithAuth({
        url: ACTIVITY_API + '/get-activity-type-list', options: { method: 'GET' }
    })
}

export function fetchGetActivityStatusList() {
    return requestWithAuth({
        url: ACTIVITY_API + '/get-activity-status-list', options: { method: 'GET' }
    })
}

export function fetchCommentActivity(data) {
    return requestWithAuth({
        url: ACTIVITY_API + '/comment', options: { method: 'POST', body: JSON.stringify({ ...data }) }
    })
}

// deprecated
// todo
export function fetchUpdateActivityTypeStatusSla(data) {
    return requestWithAuth({
        url: ACTIVITY_API + '/update-activity-type-status-sla', options: { method: 'POST', body: JSON.stringify({ ...data }) }
    })
}

export function fetchGetActivityTypeStatusList(id) {
    return requestWithAuth({
        url: ACTIVITY_API + '/get-activity-type?id=' + id, options: { method: 'GET' }
    })
}

export function fetchGetActivityTypeSlaStatusList(activityTypeId) {
    return requestWithAuth({
        url: ACTIVITY_API + '/get-status-sla-by-activity-type?id=' + activityTypeId, options: { method: 'GET' }
    })
}
