import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchAddPerson, fetchCheckPhone, fetchDeletePerson, fetchGetPersonById, fetchGetPersonTypes, fetchUpdatePersonById } from '../../../api/person-api';
import { TPerson } from '../../../model/person-type';
import { TPersonType } from '../../../model/pesron-type-type';


export const fetchAddPersonAsync = createAsyncThunk(
    "person/fetchAddPersonAsync",
    fetchAddPerson
);

export const fetchGetPersonAsync = createAsyncThunk(
    "person/fetchGetPersonAsync",
    fetchGetPersonById
);

export const fetchUpdatePersonAsync = createAsyncThunk(
    "person/fetchUpdatePersonAsync",
    fetchUpdatePersonById
);

export const fetchGetPersonTypesAsync = createAsyncThunk(
    "person/fetchGetPersonTypesAsync",
    fetchGetPersonTypes
);

export const fetchDeletePersonAsync = createAsyncThunk(
    "person/fetchDeletePersonAsync",
    fetchDeletePerson
);
export const fetchCheckPhoneAsync = createAsyncThunk(
    "person/fetchCheckPhoneAsync",
    fetchCheckPhone
);
const personInitialState: TPerson = {
    fio: '',
    phone: '',
    email: '',
    description: '',
    personTypes: []
}

const initialState: { person: TPerson; personTypes: Array<TPersonType>; status: string } = {
    person: personInitialState,
    personTypes: [],
    status: 'idle'
}

export const personSlice = createSlice({
    name: 'person',
    initialState,
    extraReducers: (builder) => {
        builder
            // add person
            .addCase(fetchAddPersonAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAddPersonAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchAddPersonAsync.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            // get person
            .addCase(fetchGetPersonAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetPersonAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetPersonAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.person = action.payload.person;
                }
            })
            // udpate person
            .addCase(fetchUpdatePersonAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUpdatePersonAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchUpdatePersonAsync.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            // get person types
            .addCase(fetchGetPersonTypesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetPersonTypesAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetPersonTypesAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.personTypes = action.payload.data;
                }
            })
            .addCase(fetchDeletePersonAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchDeletePersonAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchDeletePersonAsync.fulfilled, (state, action) => {
                state.status = 'idle';
            })
    },
    reducers: {
        clearPerson: (state => {
            state.person = personInitialState;
        }),
    }
})

export const { clearPerson } = personSlice.actions;

export const getPerson = (state: RootState) => state.person.person;
export const getPersonTypes = (state: RootState) => state.person.personTypes;

export default personSlice.reducer