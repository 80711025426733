import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchGetNotificationList } from '../../../api/notification-api';
import { TNotification } from '../../../model/notification-type';



export const fetchGetNotifiactionListAsync = createAsyncThunk(
    "notifiactionList/fetchGetNotifiactionListAsync",
    fetchGetNotificationList
);

const initialState: { entities: Array<TNotification>; status: string; } = {
    entities: [],
    status: 'idle'
}

export const notificationListSlice = createSlice({
    name: 'notifiactionList',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchGetNotifiactionListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetNotifiactionListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetNotifiactionListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data;
                }
            })
    },
    reducers: {

    }
})


export const getNotifiactionList = (state: RootState) => state.notifiactionList.entities;

export default notificationListSlice.reducer