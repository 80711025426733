import { isAnyOf, isRejected } from '@reduxjs/toolkit';
import { sendToBot } from './bot/error-bot';
import { signout } from './slices/auth/authSlice';
import { setErrorMessage } from './slices/error/errorSlice';

// import { toast } from 'your-cool-library'

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger =
  (api) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!

    if (isAnyOf(isRejected)(action)) {

      api.dispatch(setErrorMessage(action.error.message))
      // TODO: log error отправлять логи в хранилище логов ui

      if (action.error.message === 'Не авторизован') {
        api.dispatch(signout())
      }

      sendToBot({ user: api.getState().auth?.user?.person?.fio, action: action.type, error: action.error })
    }
    return next(action)
  } 