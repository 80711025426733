import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '../../hooks/hooks';
import { getAuthUserRoles } from '../../services/slices/auth/authSlice';
import { getUser } from '../../services/slices/user/userSlice';
import { intersection } from '../../utils/array';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import GroupsIcon from '@mui/icons-material/Groups';
import HouseIcon from '@mui/icons-material/House';
import PeopleIcon from '@mui/icons-material/People';
import PublicIcon from '@mui/icons-material/Public';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import TimerIcon from '@mui/icons-material/Timer';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import VerifiedIcon from '@mui/icons-material/Verified';

import { Badge, Divider, Tooltip } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


import {
    ACTIVITY_TYPE_ROUTE,
    AGENCY_ROUTE,
    BANK_ROUTE,
    ESTIMATE_ROUTE,
    ESTIMATE_TYPE_ROUTE,
    HOUSE_ROUTE,
    LAND_DRILLING_ROUTE,
    LAND_INSPECTION_ROUTE,
    LAND_ROUTE,
    LAND_SEARCH_ROUTE,
    MAIN_ROUTE,
    NOTIFICATION_ROUTE,
    SPECIALIST_ROUTE,
    TICKET_ROUTE,
    USER_ROUTE
} from '../../route';
import LocalStorageDB from '../../utils/localStorageDB';


export const OFFICE_USERS = [
    'ADMIN',
    'CHAT CONTROL',
    'GOD EYE',
    'OFFICE MANAGER',
    'LAND SEARCH',
    'LAND DRILLING',
    'LAND INSPECTION',
    'CURATOR',
    'ESTIMATOR',
]

export const ADDITIONAL_USERS = [
    'chief engineer',
    'Head of the Construction Department',
    'development of the calculator'
]


const NAV_LIST = [
    { name: '', text: 'Клиенты', link: MAIN_ROUTE, icon: <GroupsIcon />, roles: [...OFFICE_USERS] },
    { name: 'estimate', text: 'Сметы', link: ESTIMATE_ROUTE, icon: <AssignmentTurnedInIcon />, roles: [...OFFICE_USERS] },
    { name: 'ticket', text: 'Тикеты', link: TICKET_ROUTE, icon: <QuestionAnswerIcon />, roles: [...OFFICE_USERS, ...ADDITIONAL_USERS] },
    { name: '', text: 'Участки', link: LAND_ROUTE, icon: <PublicIcon />, roles: [...OFFICE_USERS] },
    { name: 'land search', text: 'Поиск участков', link: LAND_SEARCH_ROUTE, icon: <TravelExploreIcon />, roles: [...OFFICE_USERS] },
    { name: 'land inspection', text: 'Провeрка участков', link: LAND_INSPECTION_ROUTE, icon: <VerifiedIcon />, roles: [...OFFICE_USERS] },
    { name: 'land drilling', text: 'Пробные бурения', link: LAND_DRILLING_ROUTE, icon: <ArchitectureIcon />, roles: [...OFFICE_USERS] },
]

const SECOND_LIST = [
    { name: '', text: 'Агентсва', link: AGENCY_ROUTE, icon: <SupervisedUserCircleIcon />, roles: [...OFFICE_USERS], },
    { name: '', text: 'Специалисты', link: SPECIALIST_ROUTE, icon: <ClearAllIcon />, roles: [...OFFICE_USERS], },
    { name: '', text: 'Дома', link: HOUSE_ROUTE, icon: <HouseIcon />, roles: [...OFFICE_USERS], },
    { name: '', text: 'Банки', link: BANK_ROUTE, icon: <AccountBalanceIcon />, roles: [...OFFICE_USERS], },
    { name: '', text: 'Типы смет', link: ESTIMATE_TYPE_ROUTE, icon: <FormatIndentIncreaseIcon />, roles: [...OFFICE_USERS], },
]

const THIRD_LIST = [
    { name: '', text: 'Пользователи', link: USER_ROUTE, icon: <PeopleIcon />, roles: [...OFFICE_USERS], },
    { name: '', text: 'Просрочки', link: NOTIFICATION_ROUTE, icon: <RemoveRedEyeIcon />, roles: ['ADMIN', 'GOD EYE'] },
    { name: '', text: 'SLA', link: ACTIVITY_TYPE_ROUTE, icon: <TimerIcon />, roles: ['ADMIN', 'GOD EYE'], },
]

type TNavListItem = { name: string, text: string, link: string, icon: JSX.Element, roles: Array<string> }


function ConditionalTooltip({ renderTooltip, children, title, placement }: { renderTooltip: boolean, children: any, title: string, placement: any }) {
    return renderTooltip ? <Tooltip title={title} placement={placement} >{children}</Tooltip> : children;
}

const db = new LocalStorageDB('fh_');


const DrawNavList = (list: Array<TNavListItem>) => {
    const navigate = useNavigate();
    const userRoles = useSelector(getAuthUserRoles)

    const isTooltipHide = db.fetch('is-side-bar-open')?.toString()

    return <>
        {list.map((nav: any, index: number) => {
            let badge = 0

            if (intersection(userRoles.map((role: any) => role.name), nav?.roles).length > 0) {
                return (
                    <ConditionalTooltip key={index} renderTooltip={isTooltipHide === 'close'} title={nav.text} placement={"right"}>
                        <ListItemButton onClick={() => navigate(nav.link)} >
                            <ListItemIcon>
                                <Badge badgeContent={badge} color="primary">
                                    {nav.icon}
                                </Badge>
                            </ListItemIcon>
                            <ListItemText primary={nav.text} />
                        </ListItemButton >
                    </ConditionalTooltip>
                )
            }
            return <></>
        })}
    </>
}


export const Sidebar = () => {
    const user = useSelector(getUser);

    if (!user) {
        return null;
    }

    return (
        <React.Fragment>
            {DrawNavList(NAV_LIST)}
            <Divider textAlign="left" sx={{ marginTop: '10px', marginBottom: '5px' }} ></Divider>
            {DrawNavList(SECOND_LIST)}
            <Divider textAlign="left" sx={{ marginTop: '10px', marginBottom: '5px' }} ></Divider>
            {DrawNavList(THIRD_LIST)}
        </React.Fragment >
    )
}