import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchAddSpecialist, fetchGetSpecialist, fetchGetSpecialistByUserId, fetchUpdateSpecialist } from '../../../api/specialist-api';
import { TAgent } from '../../../model/agent-type';
import { TPerson } from '../../../model/person-type';
import { TPersonType } from '../../../model/pesron-type-type';


export const fetchAddSpecialistAsync = createAsyncThunk(
    "user/fetchAddSpecialistAsync",
    fetchAddSpecialist
);

export const fetchUpdateSpecialistAsync = createAsyncThunk(
    "user/fetchUpdateSpecialistAsync",
    fetchUpdateSpecialist
);

export const fetchGetSpecialistAsync = createAsyncThunk(
    "user/fetchGetSpecialistAsync",
    fetchGetSpecialist
);

export const fetchGetSpecialistByUserIdAsync = createAsyncThunk(
    "user/fetchGetSpecialistByUserIdAsync",
    fetchGetSpecialistByUserId
);

const specialistInitialState: TPerson & { agent?: TAgent; person_types?: TPersonType[] } = {
    fio: '',
    phone: '',
    email: '',
    description: '',
    personTypes: []
}

const initialState: { entity: TPerson & { agent?: TAgent; person_types?: TPersonType[] }; status: string } = {
    entity: specialistInitialState,
    status: 'idle'
}

export const specialistSlice = createSlice({
    name: 'specialist',
    initialState,
    extraReducers: (builder) => {
        builder
            // get user
            .addCase(fetchGetSpecialistAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetSpecialistAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetSpecialistAsync.fulfilled, (state, action) => {

                if (action.payload.success) {
                    state.entity = action.payload.data;
                }
                state.status = 'idle';
            })
            // get specialist by user id
            .addCase(fetchGetSpecialistByUserIdAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetSpecialistByUserIdAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetSpecialistByUserIdAsync.fulfilled, (state, action) => {

                if (action.payload.success) {
                    state.entity = action.payload.data;
                }
                state.status = 'idle';
            })
            // add specialist
            .addCase(fetchAddSpecialistAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAddSpecialistAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchAddSpecialistAsync.fulfilled, (state) => {
                state.status = 'idle';
            })
            // update specialist
            .addCase(fetchUpdateSpecialistAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUpdateSpecialistAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchUpdateSpecialistAsync.fulfilled, (state) => {
                state.status = 'idle';
            })
    },
    reducers: {
        clearSpecialist: (state => {
            state.entity = specialistInitialState;
        }),
    }
})

export const { clearSpecialist } = specialistSlice.actions;
export const getSpecialist = (state: RootState) => state.specialist.entity;

export default specialistSlice.reducer