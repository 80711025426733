import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { SIGN_IN_ROUTE } from '../../route';

import { Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from '../../hooks/hooks';
import { getAuthUser, signout } from '../../services/slices/auth/authSlice';



const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBarStyled = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));



export const AppBar = ({ open, toggleDrawer }: { open: boolean, toggleDrawer: Function }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(getAuthUser)

    const handleSignOut = () => {
        dispatch(signout())
        navigate(SIGN_IN_ROUTE)
    }


    return (
        <AppBarStyled position="absolute" open={open}>
            <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                    background: '#821f27'
                }}
            >
                <Tooltip title={'Развернуть меню'} placement={"right"}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={e => toggleDrawer(e)}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Tooltip>

                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                </Typography>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                </Typography>
                {user?.person?.fio}
                <Tooltip title={'Выйти'} placement={"bottom"}>
                    <IconButton color="inherit" onClick={handleSignOut}>
                        <Badge color="secondary">
                            <LogoutIcon />
                        </Badge>
                    </IconButton>
                </Tooltip>

            </Toolbar>
        </AppBarStyled>
    )
}