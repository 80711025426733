import { Chip } from '@mui/material';
import { TDefault } from '../../model/default-type';

export const STATUS_COLOR = [
    { color: 'primary', status: 'new' },
]

export default function Status({ status }: { status: TDefault | undefined }) {

    const getColor = (name: string) => {
        switch (name) {
            case 'new':
            case 'wait to work':
            case 'verification request':
            case 'clarification request':
                return "info";
            case 'in progress':
            case 'eval':
            case 'verification':
            case 'clarification':
                return "warning"
            case 'done':
                return "success"
            case 'canceled':
                return "default"
            default:
                return "default"
        }
    }

    if (!status) return <></>

    return (
        <Chip
            label={status?.text}
            color={getColor(status?.name || '')}
            variant="outlined" />
    )
}
