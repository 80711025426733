import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchGetAllPerson } from '../../../api/person-api';
import { RootState } from '../..';
import { TPerson } from '../../../model/person-type';


export const fetchGetAllPersonAsync = createAsyncThunk(
    "personList/fetchGetAllPersonAsync",
    fetchGetAllPerson
);

const initialState: { entities: Array<TPerson>; status: string; } = {
    entities: [],
    status: 'idle'
}

export const personsListSlice = createSlice({
    name: 'personList',
    initialState,
    extraReducers: (builder) => {
        builder
            // get all persons
            .addCase(fetchGetAllPersonAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetAllPersonAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetAllPersonAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.persons;
                }
            })
    },
    reducers: {

    }
})


export const getPersonList = (state: RootState) => state.personList.entities;

export default personsListSlice.reducer