import { fetchGetEstimatorList } from '@/api/estimate-request-api';
import { TUser } from '@/model/user-type';
import { RootState } from '@/services';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


export const fetchGetEstimatorListAsync = createAsyncThunk(
    "estimatorList/fetchGetEstimatorListAsync",
    fetchGetEstimatorList
);


const initialState: { entities: Array<TUser>; status: string; } = {
    entities: [],
    status: 'idle'
}

export const estimatorListSlice = createSlice({
    name: 'estimatorList',
    initialState,
    extraReducers: (builder) => {
        builder
            // get list
            .addCase(fetchGetEstimatorListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetEstimatorListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetEstimatorListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data;
                }
            })
    },
    reducers: {
        clearEstimatorList: (state => {
            state.entities = [];
        }),
    }
})

export const { clearEstimatorList } = estimatorListSlice.actions;
export const getEstimatorList = (state: RootState) => state.estimatorList.entities;

export default estimatorListSlice.reducer