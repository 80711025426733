import { TDefault } from '@/model/default-type';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchGetActivityStatusList } from '../../../api/activity-api';


export const fetchGetActivityStatusListAsync = createAsyncThunk(
    "activityStatusList/fetchGetActivityStatusListAsync",
    fetchGetActivityStatusList
);

const initialState: { entities: Array<TDefault>; status: string; } = {
    entities: [],
    status: 'idle'
}

export const activityStatusListSlice = createSlice({
    name: 'activityStatusList',
    initialState,
    extraReducers: (builder) => {
        builder
            // add client
            .addCase(fetchGetActivityStatusListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetActivityStatusListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetActivityStatusListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data;
                }
            })
    },
    reducers: {
        clearActivityStatusList: (state => {
            state.entities = [];
        }),
    }
})

export const { clearActivityStatusList } = activityStatusListSlice.actions;
export const getActivityStatusList = (state: RootState) => state.activityStatusList.entities;

export default activityStatusListSlice.reducer