import { requestWithAuth } from ".";


export function fetchAddEntity(url, data) {
    return requestWithAuth({ url: url + '/add', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchGetEntity(url, id) {
    return requestWithAuth({ url: url + '/get?id=' + id, options: { method: 'GET' } })
}

export function fetchUpdateEntity(url, data) {
    return requestWithAuth({ url: url + '/update', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchDeleteEntity(url, id) {
    return requestWithAuth({ url: url + '/delete?id=' + id, options: { method: 'GET' } })
}

export function fetchFindAndCount(url, data) {
    return requestWithAuth({ url: url + '/find-and-count', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchGetList(url) {
    return requestWithAuth({ url: url + '/get-list', options: { method: 'GET' } })
}