import { fetchAddTicket, fetchGetTicket, fetchTakeToWorkTicket, fetchTicketDone, fetchUpdateTicket } from '@api/ticket-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


export const fetchAddTicketAsync = createAsyncThunk(
    "ticket/fetchAddTicketAsync",
    fetchAddTicket
);

export const fetchUpdateTicketAsync = createAsyncThunk(
    "ticket/fetchUpdateTicketAsync",
    fetchUpdateTicket
);

export const fetchGetTicketAsync = createAsyncThunk(
    "ticket/fetchGetTicketAsync",
    fetchGetTicket
);

export const fetchTakeToWorkTicketAsync = createAsyncThunk(
    "ticket/fetchTakeToWorkTicketAsync",
    fetchTakeToWorkTicket
);

export const fetchTicketDoneAsync = createAsyncThunk(
    "ticket/fetchTicketDoneAsync",
    fetchTicketDone
);

const changeDataRequests = [
    fetchAddTicketAsync,
    fetchTakeToWorkTicketAsync,
    fetchUpdateTicketAsync,
    fetchTicketDoneAsync
]

const initialState = {
    entity: {},
    status: 'idle'
}

export const TicketSlice = createSlice({
    name: 'ticket',
    initialState,
    extraReducers: (builder) => {

        changeDataRequests.forEach(req => {
            builder
                .addCase(req.pending, (state) => {
                    state.status = 'loading';
                })
                .addCase(req.rejected, (state) => {
                    state.status = 'idle';
                })
                .addCase(req.fulfilled, (state, action) => {
                    state.status = 'idle';
                })
        })

        builder
            // get 
            .addCase(fetchGetTicketAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetTicketAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetTicketAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entity = action.payload.data;
                }
            })
    },
    reducers: {
        clearTicket: (state => {
            state.entity = {};
        }),
    }
})

export const { clearTicket } = TicketSlice.actions;
export const getTicket = (state) => state.ticket.entity;

export default TicketSlice.reducer