import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchGetAgencyTelegram, fetchUpdateAgencyTelegram } from '../../../api/agency-telegram-api';


export const fetchGetAgencyTelegramAsync = createAsyncThunk(
    "agencyTelegram/fetchGetAgencyTelegramAsync",
    fetchGetAgencyTelegram
);

export const fetchUpdateAgencyTelegramAsync = createAsyncThunk(
    "agencyTelegram/fetchUpdateAgencyTelegramAsync",
    fetchUpdateAgencyTelegram
);

const initialState = {
    entity: {},
    status: 'idle'
}

export const agencyTelegramSlice = createSlice({
    name: 'agencyTelegram',
    initialState,
    extraReducers: (builder) => {
        builder
            // get agency telegram
            .addCase(fetchGetAgencyTelegramAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetAgencyTelegramAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetAgencyTelegramAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entity = action.payload.data;
                }
            })
            // update agency telegram
            .addCase(fetchUpdateAgencyTelegramAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUpdateAgencyTelegramAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchUpdateAgencyTelegramAsync.fulfilled, (state, action) => {
                state.status = 'idle';
            })
    },
    reducers: {
        clearAgencyTelegram: (state => {
            state.entity = {};
        }),
    }
})

export const { clearAgencyTelegram } = agencyTelegramSlice.actions;
export const getAgencyTelegram = (state) => state.agencyTelegram.entity;

export default agencyTelegramSlice.reducer