import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';

export const error: { message?: string } = {
    message: ''
}

const initialState: { message?: string } = {
    message: ''
}

export const errorSlice = createSlice({
    name: 'error',
    initialState,
    extraReducers: (builder) => {
    },
    reducers: {
        setErrorMessage: (state, action) => {
            state.message = action.payload
        },
        clearErrorMessage: (state) => {
            state.message = ''
        }
    }
})


export const { setErrorMessage, clearErrorMessage } = errorSlice.actions;
export const getErrorMessage = (state: RootState) => state.error.message;

export default errorSlice.reducer