import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchGetActivityTypeList } from '../../../api/activity-api';
import { TActivityType } from '../../../model/activity-model/activity-type';


export const fetchGetActivityTypeListAsync = createAsyncThunk(
    "activityTypeList/fetchGetActivityTypeListAsync",
    fetchGetActivityTypeList
);

const initialState: { entities: Array<TActivityType>; status: string; } = {
    entities: [],
    status: 'idle'
}

export const activityTypeListSlice = createSlice({
    name: 'activityTypeList',
    initialState,
    extraReducers: (builder) => {
        builder
            // add client
            .addCase(fetchGetActivityTypeListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetActivityTypeListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetActivityTypeListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data;
                }
            })
    },
    reducers: {
        clearActivityTypeList: (state => {
            state.entities = [];
        }),
    }
})

export const { clearActivityTypeList: clearAgencyList } = activityTypeListSlice.actions;
export const getActivityTypeList = (state: RootState) => state.activityTypeList.entities;

export default activityTypeListSlice.reducer