import { fetchAddEntity, fetchDeleteEntity, fetchFindAndCount, fetchGetEntity, fetchUpdateEntity } from './common'
import { requestWithAuth } from './index'

const URL = '/land'

export function fetchAddLand(data) {
    return fetchAddEntity(URL, data)
}

export function fetchGetLand(id) {
    return fetchGetEntity(URL, id)
}

export function fetchUpdateLand(data) {
    return fetchUpdateEntity(URL, data)
}

export function fetchDeleteLand(id) {
    return fetchDeleteEntity(URL, id)
}

export function fetchLandtFindAndCount(data) {
    return fetchFindAndCount(URL, data);
}

export function fetchPushToArchive(id) {
    return requestWithAuth({ url: URL + '/push-to-archive?id=' + id, options: { method: 'GET' } })
}

export function fetchPullFromArchive(id) {
    return requestWithAuth({ url: URL + '/pull-from-archive?id=' + id, options: { method: 'GET' } })
}

export function fetchGetLandList() {
    return requestWithAuth({ url: URL + '/get-list', options: { method: 'GET' } })
}

export function fetchGetLandWaterTypeList() {
    return requestWithAuth({ url: URL + '/get-water-type', options: { method: 'GET' } })
}

export function fetchCheckByCadastre() {
    return requestWithAuth({ url: URL + '/check-by-cadastre', options: { method: 'GET' } })
}