import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchGetUserList } from '../../../api/user-api';
import { TUser } from '../../../model/user-type';


export const fetchGetUserListAsync = createAsyncThunk(
    "userList/fetchGetUserListAsync",
    fetchGetUserList
);

const initialState: { entities: Array<TUser>; status: string; } = {
    entities: [],
    status: 'idle'
}

export const userListSlice = createSlice({
    name: 'userList',
    initialState,
    extraReducers: (builder) => {
        builder
            // get all persons
            .addCase(fetchGetUserListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetUserListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetUserListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.userList;
                }
            })
    },
    reducers: {}
})


export const getUserList = (state: RootState) => state.userList.entities;

export default userListSlice.reducer