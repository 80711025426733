import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchGenerateMapLink } from '../../../api/map-api';


export const fetchFetchGenerateMapLinkAsync = createAsyncThunk(
    "map/fetchFetchGenerateMapLinkAsync",
    fetchGenerateMapLink
);

const initialState: { selectedLandList: Array<number>; link: string; status: string } = {
    selectedLandList: [],
    link: '',
    status: 'idle'
}

export const mapSlice = createSlice({
    name: 'specialist',
    initialState,
    extraReducers: (builder) => {
        builder
            // generate link
            .addCase(fetchFetchGenerateMapLinkAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFetchGenerateMapLinkAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchFetchGenerateMapLinkAsync.fulfilled, (state, action) => {

                if (action.payload.success) {
                    state.link = encodeURIComponent(action.payload.data);
                }
                state.status = 'idle';
            })
    },
    reducers: {
        addLandToList: (state, action) => {
            let arr = state.selectedLandList.slice()
            arr = [...arr, action.payload];
            state.selectedLandList = arr
        },
        removeLandFromList: (state, action) => {
            let arr = state.selectedLandList.slice()
            arr = arr.filter(id => id !== action.payload);
            state.selectedLandList = arr
        },
        cleaerLandList: (state => {
            state.selectedLandList = [];
        }),
        cleaerLink: (state => {
            state.link = '';
        }),
    }
})

export const { cleaerLandList, cleaerLink, addLandToList, removeLandFromList } = mapSlice.actions;
export const getSelectedLandList = (state: RootState) => state.map.selectedLandList;
export const getGeneratedMapLink = (state: RootState) => state.map.link;

export default mapSlice.reducer