import { TTicket } from '@/model/ticket-type';
import { fetchFindAndCountTicket } from '@api/ticket-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';


export const fetchGetTicketFindAndCountAsync = createAsyncThunk(
    "ticketList/fetchFindAndCountTicketAsync",
    fetchFindAndCountTicket
);

const initialState: { count: number; entities: Array<TTicket>; status: string; } = {
    count: 0,
    entities: [],
    status: 'idle'
}

export const ticketListSlice = createSlice({
    name: 'ticketList',
    initialState,
    extraReducers: (builder) => {
        builder
            // find and count
            .addCase(fetchGetTicketFindAndCountAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetTicketFindAndCountAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetTicketFindAndCountAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.count = action.payload.data.count;
                    state.entities = action.payload.data.rows;
                }
            })
    },
    reducers: {
        clearTicketList: (state => {
            state.entities = [];
        }),
    }
})

export const { clearTicketList } = ticketListSlice.actions;
export const getTicketList = (state: RootState) => state.ticketList.entities;
export const getEstimateCount = (state: RootState) => state.ticketList.count;
export default ticketListSlice.reducer