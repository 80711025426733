import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchGetStatusList } from '../../../api/application-api';
import { RootState } from '../..';


export const fetchGetStatusListAsync = createAsyncThunk(
    "dictionary/fetchGetStatusListAsync",
    fetchGetStatusList
);


const initialState = {
    statusList: [],
    status: 'idle'
}

export const appliactionSlice = createSlice({
    name: 'application',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchGetStatusListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetStatusListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetStatusListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.statusList = action.payload.data;
                }
            })
    },
    reducers: {
    }
})


export const getApplicationStatusList = (state: RootState) => state.application.statusList;

export default appliactionSlice.reducer