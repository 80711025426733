import Fade from '@mui/material/Fade';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}


export default function TransitionsSnackbar({ open, setOpen, message = '!!!' }: { open: boolean, setOpen: (open: boolean) => void, message?: string }) {
  const [state, setState] = React.useState<{
    open: boolean;
    Transition: React.ComponentType<
      TransitionProps & {
        children: React.ReactElement<any, any>;
      }
    >;
  }>({
    open: false,
    Transition: Fade,
  });

  React.useEffect(() => {
    if (open) {
      setState({
        ...state,
        open: true,
      });
    }
  }, [open])

  // const handleClick =
  //   (
  //     Transition: React.ComponentType<
  //       TransitionProps & {
  //         children: React.ReactElement<any, any>;
  //       }
  //     >,
  //   ) =>
  //     () => {
  //       setState({
  //         open: true,
  //         Transition,
  //       });
  //     };

  return (
    <div>
      {/* <Button onClick={handleClick(SlideTransition)}>Slide Transition</Button> */}
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={SlideTransition}
        message={message}
        key={state.Transition.name}
        autoHideDuration={5000}
      />
    </div>
  );
}