import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchGetAgencyList } from '../../../api/agency-api';
import { TAgency } from '../../../model/agency-type';


export const fetchGetAgencyListAsync = createAsyncThunk(
    "agencyList/fetchGetAgencyListAsync",
    fetchGetAgencyList
);

const initialState: { entities: Array<TAgency>; status: string; } = {
    entities: [],
    status: 'idle'
}

export const agencyListSlice = createSlice({
    name: 'agencyList',
    initialState,
    extraReducers: (builder) => {
        builder
            // add client
            .addCase(fetchGetAgencyListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetAgencyListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetAgencyListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data;
                }
            })
    },
    reducers: {
        clearAgencyList: (state => {
            state.entities = [];
        }),
    }
})

export const { clearAgencyList } = agencyListSlice.actions;
export const getAgencyList = (state: RootState) => state.agencyList.entities;

export default agencyListSlice.reducer