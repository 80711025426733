import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchGetClientActivityList } from '../../../api/client-dictionary-api';


export const fetchGetClientActivityListAsync = createAsyncThunk(
    "clientDictionary/fetchGetClientActivityListAsync",
    fetchGetClientActivityList
);

const initialState = {
    clientActivityList: [],
    status: 'idle'
}

export const clientDictionarySlice = createSlice({
    name: 'clientDictionary',
    initialState,
    extraReducers: (builder) => {
        builder
            // 
            .addCase(fetchGetClientActivityListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetClientActivityListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetClientActivityListAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.clientActivityList = action.payload.data;
            })
    }
})

export const getClientLoadStatus = (state) => state.clientDictionary.status;
export const getClientActivityList = (state) => state.clientDictionary.clientActivityList;
export default clientDictionarySlice.reducer