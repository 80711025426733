import { requestWithAuth } from "."
import { fetchAddEntity, fetchFindAndCount, fetchGetEntity, fetchUpdateEntity } from './common'


const ESTIMATE_API = '/estimate-request'
const URL = ESTIMATE_API



export function fetchAddEstimateRequest(data) {
    return fetchAddEntity(URL, data)
}

export function fetchGetEstimateRequest(id) {
    return fetchGetEntity(URL, id)
}

// передать на исполнение
export function fetchReturnToWorkEstimateRequest(data) {
    return requestWithAuth({
        url: ESTIMATE_API + '/return-to-work', options: { method: 'POST', body: JSON.stringify({ ...data }) }
    })
}

export function fetchUpdateEstimateRequest(data) {
    return fetchUpdateEntity(URL, data)
}

export function fetchTakeToWorkEstimateRequest(id) {
    return requestWithAuth({
        url: ESTIMATE_API + '/to-work?id=' + id, options: { method: 'GET' }
    })
}

export function fetchReturnForClarificationEstimateRequest(data) {
    return requestWithAuth({
        url: ESTIMATE_API + '/retrun-for-clarification', options: { method: 'POST', body: JSON.stringify({ ...data }) }
    })
}


export function fetchTakeForClarificationEstimateRequest(id) {
    return requestWithAuth({
        url: ESTIMATE_API + '/take-for-clarification?id=' + id, options: { method: 'GET' }
    })
}

export function fetchToVerificationEstimateRequest(data) {
    return requestWithAuth({
        url: ESTIMATE_API + '/to-verification', options: { method: 'POST', body: JSON.stringify({ ...data }) }
    })
}


export function fetchVerificationRequest(id) {
    return requestWithAuth({
        url: ESTIMATE_API + '/verification?id=' + id, options: { method: 'GET' }
    })
}

export function fetchGetEstimateListRequest() {
    return requestWithAuth({
        url: ESTIMATE_API + '/get-list', options: { method: 'GET' }
    })
}

export function fetchGetEstimateRequestFindAndCount(data) {
    return fetchFindAndCount(ESTIMATE_API, data)
}

export function fetchGetTypeListEstimateRequest() {
    return requestWithAuth({ url: URL + '/get-estimate-type-list', options: { method: 'GET' } })
}

export function fetchDoneEstimateRequest(id) {
    return requestWithAuth({
        url: ESTIMATE_API + '/done?id=' + id, options: { method: 'GET' }
    })
}

export function fetchSendToChatEstimateRequest(id) {
    return requestWithAuth({
        url: ESTIMATE_API + '/sended-to-chat?id=' + id, options: { method: 'GET' }
    })
}

export function fetchCancelEstimateRequest(id) {
    return requestWithAuth({
        url: ESTIMATE_API + '/cancel?id=' + id, options: { method: 'GET' }
    })
}

export function fetchGetEstimatorList() {
    return requestWithAuth({
        url: ESTIMATE_API + '/get-estimator-list', options: { method: 'GET' }
    })
}