import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchGetFileList } from '../../../api/file-api';
import { TDefault } from '../../../model/default-type';


export const fetchGetFileListAsync = createAsyncThunk(
    "fileList/fetchGetFileListAsync",
    fetchGetFileList
);


const initialState: { entities: TDefault[]; status: string } = {
    entities: [],
    status: 'idle'
}

export const fileListSlice = createSlice({
    name: 'fileList',
    initialState,
    extraReducers: (builder) => {
        builder
            // get land
            .addCase(fetchGetFileListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetFileListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetFileListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data
                }
            })
    },
    reducers: {
        clearFileList: (state => {
            state.entities = [];
        }),
    }
})

export const { clearFileList } = fileListSlice.actions;
export const getFileList = (state: RootState) => state.fileList.entities;

export default fileListSlice.reducer