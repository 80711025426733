import { getCookie } from '../utils/coockie.js'
import { request, requestWithAuth } from './index'

const AUTH_API = '/user'

export function signin(data) {
    return request({ url: AUTH_API + '/sign-in', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function signout() {
    return request({ url: AUTH_API + '/sign-out', options: { method: 'POST' } })
}

export function signup(userData) {
    return request({
        url: AUTH_API + '/sign-up', options: {
            method: 'POST', body: JSON.stringify(userData)
        }
    })
}

export function updateUserRoles(data) {
    return requestWithAuth({
        url: AUTH_API + '/update-roles',
        options: {
            method: 'POST',
            body: JSON.stringify({ ...data }),
        }
    })
}

export async function getUserData() {
    return await request({
        url: AUTH_API + '/profile',
        options: {
            method: 'GET',
            headers: { authorization: 'Bearer ' + getCookie('token') }
        }
    })
        .catch((err) => {
            return err
        })
}
