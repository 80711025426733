import SignIn from '@pages/sign-in/sign-in';
import { lazy } from 'react';
import { Routes as DomRoutes, Route } from 'react-router-dom';
import { ACTIVITY_TYPE_ID_ROUTE, ACTIVITY_TYPE_ROUTE, AGENCY_ID_ROUTE, AGENCY_ROUTE, AGENCY_TELEGRAM_ROUTE, AGENT_ADD_ROUTE, AGENT_ID_ROUTE, BANK_ADD_ROUTE, BANK_ID_ROUTE, BANK_ROUTE, CLIENT_ADD_ROUTE, CLIENT_ID_ROUTE, CLIENT_ROUTE, ESTIMATE_ADD_ROUTE, ESTIMATE_ID_ROUTE, ESTIMATE_ROUTE, ESTIMATE_TYPE_ADD_ROUTE, ESTIMATE_TYPE_ID_ROUTE, ESTIMATE_TYPE_ROUTE, HOUSE_ADD_ROUTE, HOUSE_ID_ROUTE, HOUSE_ROUTE, LAND_ADD_ROUTE, LAND_DRILLING_ADD_ROUTE, LAND_DRILLING_ID_ROUTE, LAND_DRILLING_ROUTE, LAND_ID_ROUTE, LAND_INSPECTION_ADD_ROUTE, LAND_INSPECTION_ID_ROUTE, LAND_INSPECTION_ROUTE, LAND_ROUTE, LAND_SEARCH_ADD_ROUTE, LAND_SEARCH_ID_ROUTE, LAND_SEARCH_ROUTE, MAIN_ROUTE, NOTIFICATION_ROUTE, SIGN_IN_ROUTE, SPECIALIST_ADD_ROUTE, SPECIALIST_ID_ROUTE, SPECIALIST_ROUTE, TICKET_ADD_ROUTE, TICKET_ID_ROUTE, TICKET_ROUTE, USER_ADD_ROUTE, USER_ID_ROUTE, USER_ROUTE } from '../../route';
import { ProtectedRouteElement } from '../protected-route';
import { UnauthorizedUserRouteElement } from '../unauthorized-user-route';



const LazyClientListComponent = lazy(
    () => import('@pages/client-list/client-list')
)
const LazyClientComponent = lazy(
    () => import('@pages/client/client')
)
const LazyLandListComponent = lazy(
    () => import('@pages/land-list/land-list')
)
const LazyLandComponent = lazy(
    () => import('@pages/land/land')
)
const LazyLandInspectionListComponent = lazy(
    () => import('@pages/land-inspection-list/land-inspection-list')
)
const LazyLandInspectionComponent = lazy(
    () => import('@pages/land-inspection/land-inspection')
)
const LazyLandSearchListComponent = lazy(
    () => import('@pages/land-search-list/land-search-list')
)
const LazyLandSearchComponent = lazy(
    () => import('@pages/land-search/land-search')
)
const LazyLandDrillingListComponent = lazy(
    () => import('@pages/land-drilling-list/land-drilling-list')
)
const LazyLandDrillingComponent = lazy(
    () => import('@pages/land-drilling/land-drilling')
)
const LazyAgencyComponent = lazy(
    () => import('@pages/agency/agency')
)
const LazyAgencyListComponent = lazy(
    () => import('@pages/agency-list/agency-list')
)
const LazyAgentComponent = lazy(
    () => import('@pages/agent/agent')
)
const LazySpecialistComponent = lazy(
    () => import('@pages/specialist/specialist')
)
const LazySpecialistListComponent = lazy(
    () => import('@pages/specialist-list/specialist-list')
)
const LazyUserComponent = lazy(
    () => import('@pages/user/user')
)
const LazyUserListComponent = lazy(
    () => import('@pages/user-list/user-list')
)
const LazyNotificationListComponent = lazy(
    () => import('@pages/notificaation-list/notification-list')
)
const LazyAgencyTelegramComponent = lazy(
    () => import('@pages/agency-telegram/agency-telegram')
)

// estimate request
const LazyEstimateRequestListComponent = lazy(
    () => import('@pages/estimate-request-list/estimate-request-list')
)
const LazyEstimateRequestComponent = lazy(
    () => import('@pages/estimate-request/estimate-request')
)

// estimate request type
const LazyEstimateRequestTypeComponent = lazy(
    () => import('@pages/estimate-request-type/estimate-request-type')
)
const LazyEstimateRequestTypeListComponent = lazy(
    () => import('@pages/estimate-request-type/estimate-request-type-list')
)

// house
const LazyHouseComponent = lazy(
    () => import('@pages/house/house')
)
const LazyHouseListComponent = lazy(
    () => import('@pages/house-list/house-list')
)

// ticket
const LazyTicketComponent = lazy(
    () => import('@pages/ticket/ticket')
)
const LazyTicketListComponent = lazy(
    () => import('@pages/ticket/ticket-list')
)

const LazyBankComponent = lazy(
    () => import('@pages//bank/bank')
)
const LazyBankListComponent = lazy(
    () => import('@pages/bank-list/bank-list')
)
const LazyActivityTypeListComponent = lazy(
    () => import('@pages/activity-type-list/activity-type-list')
)
const LazyActivityTypeIdComponent = lazy(
    () => import('@pages/activity-type/activity-type')
)


const Routes = () => {
    return (
        <DomRoutes >
            <Route path={SIGN_IN_ROUTE} element={<UnauthorizedUserRouteElement element={<SignIn />} />} />

            <Route path={MAIN_ROUTE} element={<ProtectedRouteElement element={<LazyClientListComponent />} />} />

            <Route path={AGENCY_ROUTE} element={<ProtectedRouteElement element={<LazyAgencyListComponent />} />} />
            <Route path={AGENCY_ID_ROUTE} element={<ProtectedRouteElement element={<LazyAgencyComponent />} />} />
            <Route path={AGENCY_TELEGRAM_ROUTE} element={<ProtectedRouteElement element={<LazyAgencyTelegramComponent />} />} />

            <Route path={AGENT_ID_ROUTE} element={<ProtectedRouteElement element={<LazyAgentComponent />} />} />
            <Route path={AGENT_ADD_ROUTE} element={<ProtectedRouteElement element={<LazyAgentComponent />} />} />

            <Route path={USER_ROUTE} element={<ProtectedRouteElement element={<LazyUserListComponent />} />} />
            <Route path={USER_ADD_ROUTE} element={<ProtectedRouteElement element={<LazyUserComponent />} />} />
            <Route path={USER_ID_ROUTE} element={<ProtectedRouteElement element={<LazyUserComponent />} />} />

            <Route path={SPECIALIST_ROUTE} element={<ProtectedRouteElement element={<LazySpecialistListComponent />} />} />
            <Route path={SPECIALIST_ADD_ROUTE} element={<ProtectedRouteElement element={<LazySpecialistComponent />} />} />
            <Route path={SPECIALIST_ID_ROUTE} element={<ProtectedRouteElement element={<LazySpecialistComponent />} />} />

            <Route path={CLIENT_ROUTE} element={<ProtectedRouteElement element={<LazyClientListComponent />} />} />
            <Route path={CLIENT_ADD_ROUTE} element={<ProtectedRouteElement element={<LazyClientComponent />} />} />
            <Route path={CLIENT_ID_ROUTE} element={<ProtectedRouteElement element={<LazyClientComponent />} />} />

            <Route path={LAND_ROUTE} element={<ProtectedRouteElement element={<LazyLandListComponent />} />} />
            <Route path={LAND_ADD_ROUTE} element={<ProtectedRouteElement element={<LazyLandComponent />} />} />
            <Route path={LAND_ID_ROUTE} element={<ProtectedRouteElement element={<LazyLandComponent />} />} />

            <Route path={LAND_SEARCH_ROUTE} element={<ProtectedRouteElement element={<LazyLandSearchListComponent />} />} />
            <Route path={LAND_SEARCH_ADD_ROUTE} element={<ProtectedRouteElement element={<LazyLandSearchComponent />} />} />
            <Route path={LAND_SEARCH_ID_ROUTE} element={<ProtectedRouteElement element={<LazyLandSearchComponent />} />} />

            <Route path={LAND_INSPECTION_ROUTE} element={<ProtectedRouteElement element={<LazyLandInspectionListComponent />} />} />
            <Route path={LAND_INSPECTION_ADD_ROUTE} element={<ProtectedRouteElement element={<LazyLandInspectionComponent />} />} />
            <Route path={LAND_INSPECTION_ID_ROUTE} element={<ProtectedRouteElement element={<LazyLandInspectionComponent />} />} />

            <Route path={LAND_DRILLING_ROUTE} element={<ProtectedRouteElement element={<LazyLandDrillingListComponent />} />} />
            <Route path={LAND_DRILLING_ADD_ROUTE} element={<ProtectedRouteElement element={<LazyLandDrillingComponent />} />} />
            <Route path={LAND_DRILLING_ID_ROUTE} element={<ProtectedRouteElement element={<LazyLandDrillingComponent />} />} />

            <Route path={ESTIMATE_ROUTE} element={<ProtectedRouteElement element={<LazyEstimateRequestListComponent />} />} />
            <Route path={ESTIMATE_ADD_ROUTE} element={<ProtectedRouteElement element={<LazyEstimateRequestComponent />} />} />
            <Route path={ESTIMATE_ID_ROUTE} element={<ProtectedRouteElement element={<LazyEstimateRequestComponent />} />} />

            <Route path={ESTIMATE_TYPE_ROUTE} element={<ProtectedRouteElement element={<LazyEstimateRequestTypeListComponent />} />} />
            <Route path={ESTIMATE_TYPE_ADD_ROUTE} element={<ProtectedRouteElement element={<LazyEstimateRequestTypeComponent />} />} />
            <Route path={ESTIMATE_TYPE_ID_ROUTE} element={<ProtectedRouteElement element={<LazyEstimateRequestTypeComponent />} />} />

            <Route path={HOUSE_ROUTE} element={<ProtectedRouteElement element={<LazyHouseListComponent />} />} />
            <Route path={HOUSE_ADD_ROUTE} element={<ProtectedRouteElement element={<LazyHouseComponent />} />} />
            <Route path={HOUSE_ID_ROUTE} element={<ProtectedRouteElement element={<LazyHouseComponent />} />} />

            <Route path={BANK_ROUTE} element={<ProtectedRouteElement element={<LazyBankListComponent />} />} />
            <Route path={BANK_ADD_ROUTE} element={<ProtectedRouteElement element={<LazyBankComponent />} />} />
            <Route path={BANK_ID_ROUTE} element={<ProtectedRouteElement element={<LazyBankComponent />} />} />

            <Route path={NOTIFICATION_ROUTE} element={<ProtectedRouteElement element={<LazyNotificationListComponent />} />} />

            <Route path={ACTIVITY_TYPE_ROUTE} element={<ProtectedRouteElement element={<LazyActivityTypeListComponent />} />} />
            <Route path={ACTIVITY_TYPE_ID_ROUTE} element={<ProtectedRouteElement element={<LazyActivityTypeIdComponent />} />} />

            <Route path={TICKET_ROUTE} element={<ProtectedRouteElement element={<LazyTicketListComponent />} />} />
            <Route path={TICKET_ADD_ROUTE} element={<ProtectedRouteElement element={<LazyTicketComponent />} />} />
            <Route path={TICKET_ID_ROUTE} element={<ProtectedRouteElement element={<LazyTicketComponent />} />} />


        </ DomRoutes>
    );
}

export default Routes;
