import { TDefault } from '@/model/default-type';
import { fetchAddEstimateRequestType, fetchGetEstimateRequestType, fetchUpdateEstimateRequestType } from '@api/estimate-request-type-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';


export const fetchAddEstimateRequestTypeAsync = createAsyncThunk(
    "estimateRequestType/fetchAddEstimateRequestTypeAsync",
    fetchAddEstimateRequestType
);

export const fetchUpdateEstimateRequestTypeAsync = createAsyncThunk(
    "estimateRequestType/fetchUpdateEstimateRequestTypeAsync",
    fetchUpdateEstimateRequestType
);

export const fetchGetEstimateRequestTypeAsync = createAsyncThunk(
    "estimateRequestType/fetchGetEstimateRequestTypeAsync",
    fetchGetEstimateRequestType
);

export interface IEstimateRequestType extends TDefault {
    shortName: string;
    color?: string;
    isHidden: boolean;
    number?: string;
}

const initialState: { entity: IEstimateRequestType; status: string; } = {
    entity: {} as IEstimateRequestType,
    status: 'idle'
}

export const estimateRequestTypeSlice = createSlice({
    name: 'estimateRequestType',
    initialState,
    extraReducers: (builder) => {
        builder

            // add estimateRequestType
            .addCase(fetchAddEstimateRequestTypeAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAddEstimateRequestTypeAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchAddEstimateRequestTypeAsync.fulfilled, (state) => {
                state.status = 'idle';
            })

            // update estimateRequestType
            .addCase(fetchUpdateEstimateRequestTypeAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUpdateEstimateRequestTypeAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchUpdateEstimateRequestTypeAsync.fulfilled, (state) => {
                state.status = 'idle';
            })

            // get estimateRequestType
            .addCase(fetchGetEstimateRequestTypeAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetEstimateRequestTypeAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetEstimateRequestTypeAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entity = action.payload.data;
                }
            })
    },
    reducers: {
        clearEstimateRequestType: (state => {
            state.entity = {} as IEstimateRequestType
        }),
    }
})

export const { clearEstimateRequestType } = estimateRequestTypeSlice.actions;
export const getEstimateRequestType = (state: RootState) => state.estimateRequestType.entity;


export default estimateRequestTypeSlice.reducer