import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { SIGN_IN_ROUTE } from '../route';
import { getAuthUser } from '../services/slices/auth/authSlice';


export const ProtectedRouteElement = ({ element }) => {
    const user = useSelector(getAuthUser);

    if (!user) {
        return null;
    }

    return user.email ? element : <Navigate to={SIGN_IN_ROUTE} replace />;
}