// import SignIn from '../signin/signin';
import { Box, Tooltip } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FC, Suspense, useEffect, useState } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Routes from '../routes/routes';
import { Sidebar } from '../sidebar/sidebar';


import { StatusHistory } from '@/components/status-history/status-history';
import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDispatch } from '../../hooks/hooks';
import SignIn from '../../pages/sign-in/sign-in';
import { fetchGetUserDataAsync, getAuthUser, getLoadStatus } from '../../services/slices/auth/authSlice';
import LocalStorageDB from '../../utils/localStorageDB.js';
import { AppBar } from '../app-bar/app-bar';
import { Drawer } from '../drawer/drawer';
import FatchErorrSnackbar from '../fatch-error/fatch-error';
import { Loading } from '../loading/loading';
import TransitionsSnackbar from '../snackbar/snackbar';
import { UnauthorizedUserRouteElement } from '../unauthorized-user-route';



const db = new LocalStorageDB('fh_');
// db.save('is-side-bar-open', 'https://myrusakov.ru');
// console.log(db.fetch('url'))


// TODO remove, this demo shouldn't need to reset the theme.
// const defaultTheme = createTheme();


const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          "&.MuiContainer-maxWidthLg": {
            maxWidth: "1920px",
          },
          // Add other classes as needed
        },
      },
    },
  },
});


const App: FC = () => {
  const dispatch = useDispatch();
  let location = useLocation();

  const user = useSelector(getAuthUser)
  const loadStatus = useSelector(getLoadStatus)
  const [open, setOpen] = useState<boolean>(true);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // const [IsSideBarOpen, setIsSideBarOpen] = useState<boolean>(true);

  // check connection
  window.addEventListener("offline", (e) => {
    setOpenSnackbar(false);
    setSnackbarMessage("Нет соединения с интернетом");
    setOpenSnackbar(true);
  });

  window.addEventListener("online", (e) => {
    setOpenSnackbar(false);
    setSnackbarMessage("Соединение восстановлено");
    setOpenSnackbar(true);
  });
  //

  useEffect(() => {
    dispatch(fetchGetUserDataAsync())

    let isSideBarOpen = db.fetch('is-side-bar-open')?.toString()
    setOpen(isSideBarOpen === 'close' ? false : true)

    if (isSideBarOpen === 'open') {
      setOpen(true)
    } else if (isSideBarOpen === undefined) {
      setOpen(true)
      db.save('is-side-bar-open', 'open');
    } else {
      setOpen(false)
      db.save('is-side-bar-open', 'close');
    }

  }, [])

  useEffect(() => {
    const fetchAuth = async () => {
      await dispatch(fetchGetUserDataAsync())
    }

    if (user.email) {
      fetchAuth();
    }
  }, [location.pathname])

  const toggleDrawer = () => {
    db.save('is-side-bar-open', open ? 'close' : 'open');
    setOpen(!open);
  };

  if (loadStatus === 'loading')
    return <Loading />


  return (

    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={5}>

        {(user.email) ?
          <>
            <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <AppBar open={open} toggleDrawer={toggleDrawer} />
              <Drawer variant="permanent" open={open}>
                <Toolbar
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: [1],
                  }}
                >
                  <img src={require('./logo.svg').default} alt='Frame House logo' width='114px' style={{ marginLeft: '-25px' }} />
                  <Tooltip title={'Свернуть меню'} placement={"right"}>
                    <IconButton onClick={toggleDrawer}>
                      <ChevronLeftIcon />
                    </IconButton>
                  </Tooltip>

                </Toolbar>
                <Divider />
                <List component="nav">
                  <Sidebar />
                </List>
              </Drawer>
              <Box
                component="main"
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
                  flexGrow: 1,
                  height: '100vh',
                  overflow: 'auto',
                  maxWidth: '100%',
                }}
              >
                <Toolbar />
                <Container sx={{ mt: 4, mb: 4, maxWidth: '1920px', }}  >
                  <Grid container >
                    <Grid item xs={12}>
                      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        {(user.email && loadStatus === 'idle') ?
                          <Suspense fallback={<Loading />} >
                            <Routes />
                          </Suspense>
                          : <Loading />}
                      </Paper>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </Box>
          </>
          :
          <UnauthorizedUserRouteElement element={<SignIn />} />
        }
        <TransitionsSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
        <FatchErorrSnackbar />
        {/* <ErorrSnackbar /> */}
      </SnackbarProvider>
      <StatusHistory />
    </ThemeProvider >
  );
}

export default App;
