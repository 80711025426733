import { fetchAddEstimate, fetchAddFormalizedEstimate, fetchGetEstimate, fetchGetEstimateTypeList, fetchTakeEstimateToWork, fetchUpdateEstimate } from '@api/estimate-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


export const fetchAddEstimateAsync = createAsyncThunk(
    "estimate/fetchAddEstimateAsync",
    fetchAddEstimate
);

export const fetchAddFormalizedEstimateAsync = createAsyncThunk(
    "estimate/fetchAddFormalizedEstimateAsync",
    fetchAddFormalizedEstimate
);

export const fetchUpdateEstimateAsync = createAsyncThunk(
    "estimate/fetchUpdateEstimateAsync",
    fetchUpdateEstimate
);

export const fetchTakeEstimateToWorkAsync = createAsyncThunk(
    "estimate/fetchTakeEstimateToWorkAsync",
    fetchTakeEstimateToWork
);

export const fetchGetEstimateAsync = createAsyncThunk(
    "estimate/fetchGetEstimateAsync",
    fetchGetEstimate
);

export const fetchGetEstimateTypeListAsync = createAsyncThunk(
    "estimate/fetchGetEstimateTypeListAsync",
    fetchGetEstimateTypeList
);

const initialState = {
    entity: {},
    status: 'idle',
    estimateTypeList: []
}

const changeDataRequests = [
    fetchAddEstimateAsync,
    fetchUpdateEstimateAsync,
    fetchTakeEstimateToWorkAsync,
    fetchAddFormalizedEstimateAsync,
]

export const estimateSlice = createSlice({
    name: 'estimate',
    initialState,
    extraReducers: (builder) => {
        changeDataRequests.forEach(req => {
            builder
                .addCase(req.pending, (state) => {
                    state.status = 'loading';
                })
                .addCase(req.rejected, (state) => {
                    state.status = 'idle';
                })
                .addCase(req.fulfilled, (state) => {
                    state.status = 'idle';
                })
        })
        builder
            // get estimate
            .addCase(fetchGetEstimateAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetEstimateAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetEstimateAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entity = action.payload.data;
                }
            })
            // get estimate type list
            .addCase(fetchGetEstimateTypeListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetEstimateTypeListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetEstimateTypeListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.estimateTypeList = action.payload.data;
                }
            })
    },
    reducers: {
        clearEstimate: (state => {
            state.entity = {};
        }),
        clearEstimateTypeList: (state => {
            state.estimateTypeList = [];
        }),
        changeEstimateType: ((state, action) => {
            state.entity.estimateType = action.payload;
            state.entity.estimateTypeId = action.payload.id;
        }),
    }
})

export const { clearEstimate, clearEstimateTypeList, changeEstimateType } = estimateSlice.actions;
export const getEstimate = (state) => state.estimate.entity;
export const getEstimateTypeList = (state) => state.estimate.estimateTypeList;

export default estimateSlice.reducer