export const MAIN_ROUTE = '/';

export const SIGN_IN_ROUTE = '/sign-in'
export const SING_UP_ROUTE = '/sign-up'


export const ACTIVITY_TYPE_ROUTE = '/activity'
export const ACTIVITY_TYPE_ID_ROUTE = ACTIVITY_TYPE_ROUTE + '/:id'

export const AGENCY_ROUTE = '/agency';
export const AGENCY_ID_ROUTE = AGENCY_ROUTE + '/:id'
export const AGENCY_TELEGRAM_ROUTE = AGENCY_ROUTE + '/:id/telegram'

export const AGENT_ROUTE = '/agent'
export const AGENT_ID_ROUTE = AGENCY_ROUTE + '/:id' + AGENT_ROUTE + '/:agentId'
export const AGENT_ADD_ROUTE = AGENCY_ROUTE + '/:id' + AGENT_ROUTE + '/add'

export const USER_ROUTE = '/user';
export const USER_ADD_ROUTE = USER_ROUTE + '/add'
export const USER_ID_ROUTE = USER_ROUTE + '/:id'

export const SPECIALIST_ROUTE = '/specialist';
export const SPECIALIST_ADD_ROUTE = SPECIALIST_ROUTE + '/add'
export const SPECIALIST_ID_ROUTE = SPECIALIST_ROUTE + '/:id'

export const CLIENT_ROUTE = '/client'
export const CLIENT_ADD_ROUTE = CLIENT_ROUTE + '/add'
export const CLIENT_ID_ROUTE = CLIENT_ROUTE + '/:id'

export const LAND_ROUTE = '/land'
export const LAND_ADD_ROUTE = LAND_ROUTE + '/add'
export const LAND_ID_ROUTE = LAND_ROUTE + '/:id'

export const LAND_SEARCH_ROUTE = '/land-search'
export const LAND_SEARCH_ADD_ROUTE = LAND_SEARCH_ROUTE + '/add'
export const LAND_SEARCH_ID_ROUTE = LAND_SEARCH_ROUTE + '/:id'

export const LAND_INSPECTION_ROUTE = '/land-inspection'
export const LAND_INSPECTION_ADD_ROUTE = LAND_INSPECTION_ROUTE + '/add'
export const LAND_INSPECTION_ID_ROUTE = LAND_INSPECTION_ROUTE + '/:id'

export const LAND_DRILLING_ROUTE = '/land-drilling'
export const LAND_DRILLING_ADD_ROUTE = LAND_DRILLING_ROUTE + '/add'
export const LAND_DRILLING_ID_ROUTE = LAND_DRILLING_ROUTE + '/:id'

export const HOUSE_ROUTE = '/house'
export const HOUSE_ADD_ROUTE = HOUSE_ROUTE + '/add'
export const HOUSE_ID_ROUTE = HOUSE_ROUTE + '/:id'

export const BANK_ROUTE = '/bank'
export const BANK_ADD_ROUTE = BANK_ROUTE + '/add'
export const BANK_ID_ROUTE = BANK_ROUTE + '/:id'

export const ESTIMATE_ROUTE = '/estimate'
export const ESTIMATE_ADD_ROUTE = ESTIMATE_ROUTE + '/add'
export const ESTIMATE_ID_ROUTE = ESTIMATE_ROUTE + '/:id'

export const ESTIMATE_TYPE_ROUTE = '/estimate-type'
export const ESTIMATE_TYPE_ADD_ROUTE = ESTIMATE_TYPE_ROUTE + '/add'
export const ESTIMATE_TYPE_ID_ROUTE = ESTIMATE_TYPE_ROUTE + '/:id'

export const TICKET_ROUTE = '/ticket'
export const TICKET_ADD_ROUTE = TICKET_ROUTE + '/add'
export const TICKET_ID_ROUTE = TICKET_ROUTE + '/:id'

export const NOTIFICATION_ROUTE = '/notification'