import { requestWithAuth } from './index'

const FILE_API = '/file'

export function fetchUploadFile(data) {
    return requestWithAuth({ url: FILE_API + '/upload', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}

export function fetchDeleteFile(id) {
    console.log('fetchDeleteFile', id)
    return requestWithAuth({ url: FILE_API + '/delete?id=' + id, options: { method: 'GET' } })
}

export function fetchGetFileList(data) {
    return requestWithAuth({ url: FILE_API + '/get-list', options: { method: 'POST', body: JSON.stringify({ ...data }) } })
}