import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { fetchGetLandDrillingList } from '../../../api/land-drilling-api';
import { TLandDrilling } from '../../../model/land-model/land-drilling-type';


export const fetchGetLandDrillingListAsync = createAsyncThunk(
    "landDrillingList/fetchGetLandDrillingListAsync",
    fetchGetLandDrillingList
);

const initialState: { entities: Array<TLandDrilling>; status: string; } = {
    entities: [],
    status: 'idle'
}

export const landDrillingListSlice = createSlice({
    name: 'landDrillingList',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchGetLandDrillingListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetLandDrillingListAsync.rejected, (state) => {
                state.status = 'idle';
            })
            .addCase(fetchGetLandDrillingListAsync.fulfilled, (state, action) => {
                state.status = 'idle';

                if (action.payload.success) {
                    state.entities = action.payload.data;
                }
            })
    },
    reducers: {

    }
})


export const getLandDrillingList = (state: RootState) => state.landDrillingList.entities;
export const getLandDrillingListLoadStatus = (state: RootState) => state.landDrillingList.status;

export default landDrillingListSlice.reducer